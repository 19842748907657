import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import MeasurementList from './../../components/Measurement/MeasurementList';
const MeasurementListPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <MeasurementList/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};
export default MeasurementListPage;