import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import CreditList from '../../components/Transaction/CreditList';
const CreditListPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <CreditList/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};
export default CreditListPage;