import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';

import { togglePermission,setSelectedPermissions,onChangeUserRolePermissionFormInput,resetUserRolePermissionFormValue } from '../../../redux/slice/admin/UserRoleSlice';
import { GetUserRoleList,GetPermissionList,RoleCreateRequest,FillUserRoleFormRequest } from '../../apiServices/UserRoleApiService';

const UserRoleCreateUpdate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.userRoleSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetUserRoleList();
            await GetPermissionList();
        })();
        store.dispatch(resetUserRolePermissionFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillUserRoleFormRequest(id);
                console.log(formValue)
            })();
        }else{
            setRowID(0)
        }
        
    },[])

    let dataList=useSelector((state)=>(state.userRoleSlice.permissionList));

    const saveChange =  async() => {
        if(isEmpty(formValue.name)){
            errorToast("Name Required !")
        }
        else if(formValue.permissions.length<=0){
            errorToast("Permission Required !")
        }
        else {
            if(await RoleCreateRequest(formValue,rowRd)){
                navigate("/user/role/list")
            }
        }
    }
    const breadcumb={
        dashboard:"Dashboard",
        menu:"User Management",
        link:"/user/role/list",
        feature:rowRd !==0?"Update User Role":"Create User Role"
    }
    const isNameValid = true;
    const togglePermissionToStore = (permissionId) => {
        store.dispatch(togglePermission(permissionId));
    };
    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                                
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Name</label>
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                                !isNameValid ? 'is-invalid' : '' }`} placeholder="Name" onChange={(e)=>{store.dispatch(onChangeUserRolePermissionFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                        {!isNameValid && (
                                                <div className="invalid-feedback">Name is required.</div>
                                            )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-6"></div>
                                    
                                </div>

                                
                                <div className="row mt-4">
                                    <div className="form-group col-12 mb-3 border-bottom shadow">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  checked={formValue.permissions.length === dataList.length}
                                        onChange={() => {
                                        if (formValue.permissions.length === dataList.length) {
                                            store.dispatch(setSelectedPermissions([]));
                                        } else {
                                            const allPermissionIds = dataList.map(item => item.name);
                                            store.dispatch(setSelectedPermissions(allPermissionIds));
                                        }
                                        }}/>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Check All Permissions
                                        </label>   
                                        
                                    </div>
                                    {
                                        dataList?.map(item=>
                                            <div key={item.id} className="form-group col-md-4 col-lg-2">
                                                <input class="form-check-input" type="checkbox" value={item.name} id={`permissionCheckbox_${item.name}`}
                                                checked={formValue.permissions.includes(item.name)}
                                                onChange={() => togglePermissionToStore(item.name)} />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {item.name}
                                                </label>   
                                                
                                            </div>
                                        )
                                    }
                                    
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default UserRoleCreateUpdate;