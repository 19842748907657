// export const BaseURL = "http://localhost:8000/api/v1";
export const BaseURL = "https://erpapi.softrear.com/api/v1";
// export const BaseURL = "https://apitilescorner.softrear.com/api/v1";
export const LoginURL = "/login";
export const UserListURL = "/users";
export const UserCreateURL = "/users";
export const UserShowURL = "/users";
export const UserUpdateURL = "/users";
export const UserDeleteURL = "/users";
export const UserLoginHistoryListURL = "/users/login/history";
export const UserPackageAssignURL = "/assign/package/user";

export const UserRoleListURL = "/roles";
export const UserRoleCreateURL = "/roles";
export const UserRoleUpdateURL = "/roles";
export const PermissionListURL = "/permissions";
export const RolePermissionShowURL = "/roles";
export const RolePermissionDeleteURL = "/roles";

export const BankListURL = "/bank/account";
export const BankCreateURL = "/bank/account";
export const BankUpdateURL = "/bank/account";
export const BankShowURL = "/bank/account";
export const BankDeleteURL = "/bank/account";
export const BankTypeListRL = "/get/bank/account/type";
export const CashBalanceURL = "/cash/balance";

export const SupplierListURL = "/suppliers";
export const SupplierCreateURL = "/suppliers";
export const SupplierUpdateURL = "/suppliers";
export const SupplierShowURL = "/suppliers";
export const SupplierDeleteURL = "/suppliers";
export const SupplierTypeListRL = "/supplier/type";

export const WarehouseListURL = "/warehouse";
export const WarehouseCreateURL = "/warehouse";
export const WarehouseUpdateURL = "/warehouse";
export const WarehouseShowURL = "/warehouse";
export const WarehouseDeleteURL = "/warehouse";
export const WarehouseTypeListRL = "/get/warehouse/type";

export const MeasurementListURL = "/measurement";

export const CategoryListURL = "/categories";
export const CategoryCreateURL = "/categories";
export const CategoryUpdateURL = "/categories";
export const CategoryShowURL = "/categories";
export const CategoryDeleteURL = "/categories";
export const MeasurementTypeListRL = "/measurement/type";

export const SubCategoryListURL = "/subcategories";
export const SubCategoryCreateURL = "/subcategories";
export const SubCategoryUpdateURL = "/subcategories";
export const SubCategoryShowURL = "/subcategories";
export const SubCategoryDeleteURL = "/subcategories";

export const ProductListURL = "/products";
export const ProductCreateURL = "/products";
export const ProductUpdateURL = "/products";
export const ProductShowURL = "/products";
export const ProductDeleteURL = "/products";
export const ProductGradeListURL = "/get/product/grade";
export const ProductListBySubCategory = "/get/products/subcategory";


export const PurchaseNoSeries = "/purchase/no/series";
export const PurchaseOrder = "/purchase/order";
export const PurchasePendingOrder = "/pending/purchase/order";
export const PurchaseRecieveOrder = "/recieve/purchase/order";

export const SalesNoSeries = "/sales/no/series";
export const CustomerNoSeries = "/customer/no/series";


export const CustomerTypeList = "/get/customer/type";
export const Customer = "/customer";
export const CustomerByCode = "/info/customer";

export const SalesOrder = "/sales/order"
export const SalesOrderList = "/sales/order"
export const DueSalesOrderListByCustomerId = "/due/sales/order"
export const SalesOrderReturn = "/return/sales/order"

export const TransactionList = "/transaction"
export const Transaction = "/transaction"
export const CreditTransaction = "/credit/transaction"

export const Dashboard = "/dashboard"

export const CreditCategory = "/credit/categories"
export const DocumentByCreditCategory = "/document/by/credit/category"
export const CreditTransactionList = "/credit/transaction"

export const Discount = "/discounts"
export const ProductDiscount = "/product/discounts"

export const SalesTarget = "/employeeSalesTargets"