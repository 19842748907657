import { createSlice } from '@reduxjs/toolkit'

export const UserRoleSlice = createSlice({
    name: 'userRole',
    initialState: {
        userRole: [],
        permissionList: [],
        formValue: {
            name: "",
            status: "",
            permissions: []
        }
    },
    reducers: {
        onChangeUserRolePermissionFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        togglePermission: (state, action) => {
            const permissionId = action.payload;
            if (state.formValue.permissions.includes(permissionId)) {
                state.formValue.permissions = state.formValue.permissions.filter(id => id !== permissionId);
            } else {
                state.formValue.permissions.push(permissionId);
            }
        },
        setSelectedPermissions: (state, action) => {
            state.formValue.permissions = action.payload
        },
        resetUserRolePermissionFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
            state.formValue.permissions = []
        },
        setUserRoleList: (state, action) => {
            state.userRole = action.payload
        },
        setPermissionList: (state, action) => {
            state.permissionList = action.payload
        },
    },
})

export const { onChangeUserRolePermissionFormInput, resetUserRolePermissionFormValue, setUserRoleList, setPermissionList, togglePermission, setSelectedPermissions } = UserRoleSlice.actions

export default UserRoleSlice.reducer