import { configureStore } from '@reduxjs/toolkit'
import SettingReducer from '../slice/SettingSlice'
import UserManagementSlice from '../slice/admin/UserManagementSlice'
import UserRoleSlice from '../slice/admin/UserRoleSlice'
import bankSlice from '../slice/admin/BankSlice'
import SupplierSlice from '../slice/admin/SupplierSlice'
import WarehouseSlice from '../slice/admin/WarehouseSlice'
import MeasurementSlice from '../slice/admin/MeasurementSlice'
import CategorySlice from '../slice/admin/CategorySlice'
import SubCategorySlice from '../slice/admin/SubCategorySlice'
import ProductSlice from '../slice/admin/ProductSlice'
import PurchaseOrderSlice from '../slice/admin/PurchaseOrderSlice'
import SalesOrderSlice from '../slice/admin/SalesOrderSlice'
import TransactionSlice from '../slice/admin/TransactionSlice'
import CustomerSlice from '../slice/admin/CustomerSlice'
import DashboardSlice from '../slice/admin/DashboardSlice'
import DiscountSlice from '../slice/admin/DiscountSlice'
import EmployeeSalesTargetSlice from '../slice/admin/EmployeeSalesTargetSlice'


export default configureStore({
    reducer: {
        setting: SettingReducer,
        userManagementSlice: UserManagementSlice,
        userRoleSlice: UserRoleSlice,
        bankSlice: bankSlice,
        supplierSlice: SupplierSlice,
        warehouseSlice: WarehouseSlice,
        measurementSlice: MeasurementSlice,
        categorySlice: CategorySlice,
        subCategorySlice: SubCategorySlice,
        productSlice: ProductSlice,
        purchaseOrderSlice: PurchaseOrderSlice,
        salesOrderSlice: SalesOrderSlice,
        transactionSlice: TransactionSlice,
        customerSlice: CustomerSlice,
        dashboardSlice: DashboardSlice,
        discountSlice: DiscountSlice,
        employeeSalesTargetSlice: EmployeeSalesTargetSlice
    },
})