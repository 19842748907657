class SessionHelper {
    setToken(token) {
        localStorage.setItem("token", token)
    }
    getToken() {
        return localStorage.getItem("token")
    }
    setUserDetails(UserDetails) {
        localStorage.setItem("userDetails", JSON.stringify(UserDetails))
    }
    getUserDetails() {
        return JSON.parse(localStorage.getItem("userDetails"))
    }
    setEmail(Email) {
        localStorage.setItem("email", Email)
    }
    getEmail() {
        return localStorage.getItem("email")
    }
    setOTP(OTP) {
        localStorage.setItem("OTP", OTP)
    }
    getOTP() {
        return localStorage.getItem("OTP")
    }
    removeSessions = () => {
        localStorage.clear();
        window.location.href = "/login"
    }
}
export const { setEmail, getEmail, setOTP, getOTP, setToken, getToken, setUserDetails, getUserDetails, removeSessions } = new SessionHelper();