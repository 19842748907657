import React, { Fragment, useEffect, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { errorToast, isEmpty } from "../../helper/FormHelper";
import store from "../../../redux/store/store";
import {
  onChangeTransactionFormInput,
  resetTransactionFormValue,
} from "../../../redux/slice/admin/TransactionSlice";
import { GetBankList, GetCashBalance } from "../../apiServices/BankApiService";
import {
  CashoutCreateApiService,
  GetCreditCategoryList,
  GetDocumentByCreditCategory,
} from "../../apiServices/TransactionApiService";
import Select from 'react-select';

const CashoutCreate = () => {
  let navigate = useNavigate();
  let formValue = useSelector((state) => state.transactionSlice.formValue);
  let [subCategories, setSubCategory] = useState([]);
  let [creditCategories, setCreditCategory] = useState({});
  let [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    (async () => {
      await GetBankList();
      await GetCashBalance();
      await GetCreditCategoryList();
    })();
    store.dispatch(resetTransactionFormValue());
  }, []);

  let bankList = useSelector((state) => state.bankSlice.bankList.data);
  let cashBalance = useSelector((state) => state.bankSlice.cashBalance);
  let creditCategoryList = useSelector(
    (state) => state.transactionSlice.creditCategory.data
  );
  
  const creditCategoryOptions = documentList?.map((item) => ({
    value: item.id,
    document_no: item.document_no,
    label: `${item.document_no} ${item.name ?? ''}${item.phone ?(item.phone): ''} ${item.total_due ? `(Due: ${item.total_due})` : ''}`,
  }));
  const [selectedCreditCategoryOption, setSelectedCreditCategoryOption] = useState(null);

  const handleCreditChange =async (selectedOption) => {

    setSelectedCreditCategoryOption(selectedOption);
    store.dispatch(
      onChangeTransactionFormInput({
        name: "document_no",
        value: selectedOption.document_no,
      })
    );
  };

  const saveChange = async () => {
    if (isEmpty(formValue.method)) {
      errorToast("Method required !");
    } else if (isEmpty(formValue.amount)) {
      errorToast("Amount Required !");
    } else if (isEmpty(formValue.date)) {
      errorToast("Date Required !");
    }else if (isEmpty(formValue.credit_category_id)) {
      errorToast("credit_category_id Required !");
    }else if (creditCategories && creditCategories.hasDocument && isEmpty(formValue.document_no)) {
      errorToast("document_no Required !");
    } else {
      console.log(formValue);
      if (await CashoutCreateApiService(formValue)) {
        navigate("/transaction/list");
      }
    }
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Transaction",
    link: "/transaction/list",
    feature: "Create Credit",
  };

  const changeCategoryDropdown = async (e) => {
    setSubCategory([]);
    setDocumentList([]);
    let data = await GetDocumentByCreditCategory(e.target.value);
    setDocumentList(data);
    store.dispatch(
      onChangeTransactionFormInput({
        name: "credit_category_id",
        value: e.target.value,
      })
    );
    setSelectedCreditCategoryOption(null)
    let category_id_to_find = e.target.value;
    for (const item of creditCategoryList) {
      if (item.id === Number(category_id_to_find)) {
        setCreditCategory(item)
        setSubCategory(item.creditsubcategory);
        break;
      }
    }
  };

  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2">
                <h5 class="mb-0">{breadcumb.feature}</h5>
              </div>
              <div className="card-body">
                <div className="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="email"
                        className="col-lg-3 col-form-label text-title-field required"
                      >
                        Credit Category
                      </label>
                      <div class="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(changeCategoryDropdown(e));
                          }}
                          className={`form-control admin-form-control`}
                        >
                          <option value="">Choose One</option>
                          {creditCategoryList &&
                            creditCategoryList?.map((item, index) => (
                              <option
                                value={item.id}
                                selected={
                                  formValue.credit_category_id === item.id
                                }
                              >
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="email"
                        className="col-lg-3 col-form-label text-title-field required"
                      >
                        Credit Sub Category
                      </label>
                      <div class="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "credit_sub_category_id",
                                value: e.target.value,
                              })
                            );
                          }}
                          className={`form-control admin-form-control`}
                        >
                          <option value="">Choose One</option>
                          {subCategories?.map((item, index) => (
                            <option
                              value={item.id}
                              selected={
                                formValue.credit_sub_category_id === item.id
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                      {creditCategories.label?? 'Document No'}
                    
                    
                      </label>

                      <div className="col-lg-9">
                      {
                        (creditCategories && !creditCategories.hasDocument)? <input
                          className={`form-control admin-form-control `}
                          readOnly={!creditCategories.hasDocument}
                        />:''
                      }
                      {
                        (creditCategories && creditCategories.hasDocument)?<Select
                      value={selectedCreditCategoryOption}
                      onChange={handleCreditChange}
                      options={creditCategoryOptions}
                      isSearchable={true}
                      placeholder="Choose One"

                    />:''
                      }
                      
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="email"
                        className="col-lg-3 col-form-label text-title-field required"
                      >
                        Date
                      </label>
                      <div class="col-lg-9">
                        <input
                          className={`form-control admin-form-control `}
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "date",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.date}
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="email"
                        className="col-lg-3 col-form-label text-title-field required"
                      >
                        Method
                      </label>
                      <div class="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "method",
                                value: e.target.value,
                              })
                            );
                          }}
                          className={`form-control admin-form-control`}
                        >
                          <option value="">Choose One</option>
                          <option
                            value="Bank"
                            selected={formValue.method === "Bank"}
                          >
                            Bank
                          </option>
                          <option
                            value="Cash"
                            selected={formValue.method === "Cash"}
                          >
                            Cash (Balance: {cashBalance})
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="email"
                        className="col-lg-3 col-form-label text-title-field required"
                      >
                        Bank
                      </label>
                      <div class="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "bank_id",
                                value: e.target.value,
                              })
                            );
                          }}
                          className={`form-control admin-form-control`}
                          disabled={formValue.method === "Cash"}
                        >
                          <option value="">Choose One</option>
                          {bankList?.map((item) => (
                            <option
                              key={item.id}
                              selected={formValue.bank_id === item.id}
                              value={item.id}
                            >
                              {item.name} (Balance: {item.current_balance})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Amount
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control `}
                          placeholder="amount"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "amount",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.amount}
                          type="number"
                          min="0"
                          step="1"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Discount
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control `}
                          placeholder="discount"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeTransactionFormInput({
                                name: "discount",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.discount}
                          type="number"
                          min="0"
                          step="1"
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CashoutCreate;
