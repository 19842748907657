import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { getUserDetails } from "../../helper/SessionHelper";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    color: "#2C2C2C",
  },
  section: {
    margin: 10,
    marginTop: 20,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    marginTop: 40,
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 0.2,
    fontSize: "12px",
  },
  tableCell: {
    flex:1,
    paddingRight: "1.2%",
    paddingLeft: "1.2%",
    paddingTop: "0.9%",
    paddingBottom: "0.9%",
    textAlign: "left",
  },
  tableCell2: {
    flex:1,
    paddingRight: "1.2%",
    paddingLeft: "1.2%",
    paddingTop: "0.9%",
    paddingBottom: "0.9%",
    textAlign: "left",
  },
  cellWidth1: {
    flex:1
    // width: 40, 
  },
  cellWidth2: {
    flex:2
    // width: 110,
  },
  cellWidth3: {
    flex:3
    // width: 140, 
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  bgHeader: {
    backgroundColor: "#F0F3F4",
    width: "100%",
    padding: "2%",
  },
  column: {
    flex: 1,
    marginRight: 10,
  },
  topInfo: {
    fontSize: "8px",
  },
  textRight: {
    textAlign: "right",
  },
  signatureSection: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
  },
  signatureBlock: {
    width: "50%",
    padding: 10,
    textAlign: "center",
  },
  signatureBlock2: {
    width: "70%",
    padding: 1,
    textAlign: "center",
    fontSize: "12",
  },
  signatureLine: {
    width: "45%",
    textAlign: "center",
    marginTop: 40, 
    marginBottom: 10,
    marginLeft: 70, 
    borderBottom: "1px solid #000",
  },
  signatureLine2: {
    width: "70%",
    textAlign: "center",
    marginTop: 40,
    marginBottom: 10,
    marginLeft: 28,
    borderBottom: "1px solid #000",
  },
  bottom10: {
    marginBottom: 20,
  },
  top20: {
    marginTop: 20,
  },
  heading: {
    marginTop: 4,
    fontSize: "14px",
  },
  headerTitle: {
    padding: "2%",
    fontSize: "14px",
  },
  image: {
    width: "45px",
    height: "40px",
    marginRight: "5x",
  },
  dFlex: {
    display: "flex",
  },
  rowFlex: {
    flexDirection: "row",
    width: "50%",
  },
  tableHeading:{
    fontWeight: 'bold',
    fontSize: "10px",
  },
  tableData:{
    fontSize:"10px"
  },
  evenRow: {
    backgroundColor: "#F0F0F0", // or any other color for even rows
  },
  oddRow: {
    backgroundColor: "#FFFFFF", // or any other color for odd rows
  },
  borderBottom:{
    borderBottomColor:"#000000",
    borderBottomWidth: 0.7,
  }
});

const CustomerInvoice = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.headerTitle}>
          <View style={styles.row}>
            <View
              style={{
                ...styles.column,
                ...styles.dFlex,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={styles.image}
                cache={true}
                src="/logo/logo.png"
              ></Image>
              <Text>
                Tiles Corner{"\n"}
                <Text style={styles.topInfo}> DBL Display Center</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                Poniout bypass, Brahmanbaria Sadar.
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                www.tilescornerbd.com
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                +880 9678-691699
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bgHeader}>
          <View style={styles.row}>
            {/* Customer Info */}
            <View style={styles.column}>
              <Text style={styles.heading}>Sales Order</Text>
              <Text style={styles.topInfo}>
                Ref # {invoiceData.sales_reference.name}
              </Text>
              <Text style={styles.topInfo}>Invoice # {invoiceData.so_no}</Text>
              <Text style={styles.topInfo}>Order Date: {invoiceData.date}</Text>
            </View>

            {/* Header Section */}
            <View
              style={{
                ...styles.column,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={styles.heading}>Customer Copy</Text>
            </View>

            {/* Sales Order Info */}
            <View style={{ ...styles.column, ...styles.textRight }}>
              <Text style={styles.heading}>Customer</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.code}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.name}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.phone}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.address}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={{...styles.tableRow,...styles.borderBottom}}>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>#</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Category</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth3}}>
              <Text style={styles.tableHeading}>Size</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Product</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>Grade</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Box</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Quantity</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Squarefit</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Rate/Sft</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Total</Text>
            </View>
          </View>
          {/* Sample Data */}
          {invoiceData.sales_order_items.map((item, index) => (
            <View style={{...styles.tableRow,...index % 2 === 0 ? styles.evenRow : styles.oddRow}}>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{index + 1}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.category.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth3}}>
                <Text style={styles.tableData}>{item.sub_category.measurement.cm_to_inch_sales_height}X{item.sub_category.measurement.cm_to_inch_sales_width}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.product.code}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{item.product.product_grade.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.box}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.quantity}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.squarefit}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.rate_per_squarefit}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.total}</Text>
              </View>
            </View>
          ))}
          
          <View style={[styles.tableRow, { marginTop: 10 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Sub Total</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.sub_total}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Carrying Charge</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.carrying_charge}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Labour Charge</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.labour_charge}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>VAT(%)</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.vat}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Discount</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.discount}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.total}</Text>
            </View>
          </View>
          {invoiceData.payments.map((item, index) => (
            <View style={[styles.tableRow, { marginTop: 2 }]}>
              <View style={[styles.tableCell2, { flex: 2 }]}>
                <Text style={styles.tableData}>Payment Method</Text>
              </View>

              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={[styles.tableCell2, { flex: 2 }]}>
                <Text style={styles.tableData}>{item.payment_method}</Text>
              </View>
              <View style={styles.tableCell2}>
                <Text style={styles.tableData}>{item.paid}</Text>
              </View>
            </View>
          ))}

          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total Paid</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  invoiceData.payments.reduce(
                    (total, item) => Number(total) + Number(item.paid),
                    0
                  )
                ).toFixed(2)}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Sales Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  Number(invoiceData.total) -
                    invoiceData.payments.reduce(
                      (total, item) => Number(total) + Number(item.paid),
                      0
                    )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Previous Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  Number(invoiceData.customer.total_due) -
                    Number(
                      Number(invoiceData.total) -
                        invoiceData.payments.reduce(
                          (total, item) => Number(total) + Number(item.paid),
                          0
                        )
                    )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(Number(invoiceData.customer.total_due)).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.signatureSection}>
          {/* Customer Signature */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine} />
            <Text>Signature(Customer)</Text>
          </View>

          {/* Shop Signature */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine} />
            <Text>Signature(Tiles Bajar)</Text>
            <Text>Invoiced By: {invoiceData.sales_reference.name}</Text>
          </View>
        </View>

        <View style={{ ...styles.row, ...styles.bottom10 }}>
          {/* Customer Info */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>
              {new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "Asia/Dhaka",
              })}
            </Text>
          </View>

          {/* Header Section */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>{window.location.hostname}</Text>
          </View>

          {/* Sales Order Info */}
          <View style={{ ...styles.column, ...styles.textRight }}>
            <Text style={styles.topInfo}>{getUserDetails().name}</Text>
          </View>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.headerTitle}>
          <View style={styles.row}>
            <View
              style={{
                ...styles.column,
                ...styles.dFlex,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={styles.image}
                cache={true}
                src="/logo/logo.png"
              ></Image>
              <Text>
                Tiles Corner{"\n"}
                <Text style={styles.topInfo}> DBL Display Center</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                Poniout bypass, Brahmanbaria Sadar.
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                www.tilescornerbd.com
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                +880 9678-691699
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bgHeader}>
          <View style={styles.row}>
            {/* Customer Info */}
            <View style={styles.column}>
              <Text style={styles.heading}>Sales Order</Text>
              <Text style={styles.topInfo}>
                Ref # {invoiceData.sales_reference.name}
              </Text>
              <Text style={styles.topInfo}>Invoice # {invoiceData.so_no}</Text>
              <Text style={styles.topInfo}>Order Date: {invoiceData.date}</Text>
            </View>

            {/* Header Section */}
            <View
              style={{
                ...styles.column,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={styles.heading}>Office Copy</Text>
            </View>

            {/* Sales Order Info */}
            <View style={{ ...styles.column, ...styles.textRight }}>
              <Text style={styles.heading}>Customer</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.code}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.name}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.phone}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.address}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={{...styles.tableRow,...styles.borderBottom}}>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>#</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Category</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth3}}>
              <Text style={styles.tableHeading}>Size</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Product</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>Grade</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Box</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Quantity</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Squarefit</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Rate/Sft</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Total</Text>
            </View>
          </View>
          {/* Sample Data */}
          {invoiceData.sales_order_items.map((item, index) => (
            <View style={{...styles.tableRow,...index % 2 === 0 ? styles.evenRow : styles.oddRow}}>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{index + 1}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.category.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth3}}>
                <Text style={styles.tableData}>{item.sub_category.measurement.cm_to_inch_sales_height}X{item.sub_category.measurement.cm_to_inch_sales_width}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.product.code}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{item.product.product_grade.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.box}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.quantity}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.squarefit}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.rate_per_squarefit}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.total}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { marginTop: 10 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Sub Total</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.sub_total}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Carrying Charge</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.carrying_charge}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Labour Charge</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.labour_charge}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>VAT(%)</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.vat}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Discount</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.discount}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{invoiceData.total}</Text>
            </View>
          </View>
          {invoiceData.payments.map((item, index) => (
            <View style={[styles.tableRow, { marginTop: 2 }]}>
              <View style={[styles.tableCell2, { flex: 2 }]}>
                <Text style={styles.tableData}>Payment Method</Text>
              </View>

              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={styles.tableCell2}>
                <Text></Text>
              </View>
              <View style={[styles.tableCell2, { flex: 2 }]}>
                <Text style={styles.tableData}>{item.payment_method}</Text>
              </View>
              <View style={styles.tableCell2}>
                <Text style={styles.tableData}>{item.paid}</Text>
              </View>
            </View>
          ))}

          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total Paid</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  invoiceData.payments.reduce(
                    (total, item) => Number(total) + Number(item.paid),
                    0
                  )
                ).toFixed(2)}
              </Text>
            </View>
          </View>

          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Sales Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  Number(invoiceData.total) -
                    invoiceData.payments.reduce(
                      (total, item) => Number(total) + Number(item.paid),
                      0
                    )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Previous Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(
                  Number(invoiceData.customer.total_due) -
                    Number(
                      Number(invoiceData.total) -
                        invoiceData.payments.reduce(
                          (total, item) => Number(total) + Number(item.paid),
                          0
                        )
                    )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, { marginTop: 2 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text></Text>
            </View>

            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text></Text>
            </View>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Total Due</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>
                {parseFloat(Number(invoiceData.customer.total_due)).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.signatureSection}>
          {/* Customer Signature */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine} />
            <Text>Signature(Customer)</Text>
          </View>

          {/* Shop Signature */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine} />
            <Text>Signature(Tiles Bajar)</Text>
            <Text>Invoiced By: {invoiceData.sales_reference.name}</Text>
          </View>
        </View>

        <View style={{ ...styles.row, ...styles.bottom10 }}>
          {/* Customer Info */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>
              {new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "Asia/Dhaka",
              })}
            </Text>
          </View>

          {/* Header Section */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>{window.location.hostname}</Text>
          </View>

          {/* Sales Order Info */}
          <View style={{ ...styles.column, ...styles.textRight }}>
            <Text style={styles.topInfo}>{getUserDetails().name}</Text>
          </View>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.headerTitle}>
          <View style={styles.row}>
            <View
              style={{
                ...styles.column,
                ...styles.dFlex,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={styles.image}
                cache={true}
                src="/logo/logo.png"
              ></Image>
              <Text>
                Tiles Corner{"\n"}
                <Text style={styles.topInfo}> DBL Display Center</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                Poniout bypass, Brahmanbaria Sadar.
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                www.tilescornerbd.com
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                +880 9678-691699
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bgHeader}>
          <View style={styles.row}>
            {/* Customer Info */}
            <View style={styles.column}>
              <Text style={styles.heading}>Sales Order</Text>
              <Text style={styles.topInfo}>
                Ref # {invoiceData.sales_reference.name}
              </Text>
              <Text style={styles.topInfo}>Invoice # {invoiceData.so_no}</Text>
              <Text style={styles.topInfo}>Order Date: {invoiceData.date}</Text>
            </View>

            {/* Header Section */}
            <View
              style={{
                ...styles.column,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={styles.heading}>Customer Copy</Text>
            </View>

            {/* Sales Order Info */}
            <View style={{ ...styles.column, ...styles.textRight }}>
              <Text style={styles.heading}>Customer</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.code}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.name}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.phone}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.address}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={{...styles.tableRow,...styles.borderBottom}}>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>#</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Category</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth3}}>
              <Text style={styles.tableHeading}>Size</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Product</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>Grade</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Box</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Quantity</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Squarefit</Text>
            </View>

          </View>
          {/* Sample Data */}
          {invoiceData.sales_order_items.map((item, index) => (
            <View style={{...styles.tableRow,...index % 2 === 0 ? styles.evenRow : styles.oddRow}}>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{index + 1}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.category.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth3}}>
                <Text style={styles.tableData}>{item.sub_category.measurement.cm_to_inch_sales_height}X{item.sub_category.measurement.cm_to_inch_sales_width}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.product.code}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{item.product.product_grade.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.box}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.quantity}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.squarefit}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { marginTop: 10 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Line Total</Text>
            </View>

            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.box),
                    0
                  )).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.quantity),
                    0
                  )).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.squarefit),
                    0
                  )).toFixed(2)}</Text>
            </View>
          </View>
          



        </View>
        <view style={styles.signatureSection}>
          <View style={styles.signatureBlock2}>
            <Text>Carrier Name:...........</Text>
          </View>

          <View style={styles.signatureBlock2}>
            <Text>Carrier No: ...........</Text>
          </View>
          <View style={styles.signatureBlock2}>
            <Text>Carrier Phone:...........</Text>
          </View>
        </view>
        <View style={styles.signatureSection}>
          {/* Customer Signature */}
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Customer)</Text>
          </View>
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Stock In Charge)</Text>
          </View>

          {/* Shop Signature */}
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Tiles Bajar)</Text>
          </View>
        </View>

        <View style={{ ...styles.row, ...styles.top20 }}>
          {/* Customer Info */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>
              {new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "Asia/Dhaka",
              })}
            </Text>
          </View>

          {/* Header Section */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>{window.location.hostname}</Text>
          </View>

          {/* Sales Order Info */}
          <View style={{ ...styles.column, ...styles.textRight }}>
            <Text style={styles.topInfo}>{getUserDetails().name}</Text>
          </View>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.headerTitle}>
          <View style={styles.row}>
            <View
              style={{
                ...styles.column,
                ...styles.dFlex,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={styles.image}
                cache={true}
                src="/logo/logo.png"
              ></Image>
              <Text>
                Tiles Corner{"\n"}
                <Text style={styles.topInfo}> DBL Display Center</Text>
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                Poniout bypass, Brahmanbaria Sadar.
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                www.tilescornerbd.com
              </Text>
              <Text style={{ ...styles.topInfo, ...styles.textRight }}>
                +880 9678-691699
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bgHeader}>
          <View style={styles.row}>
            {/* Customer Info */}
            <View style={styles.column}>
              <Text style={styles.heading}>Sales Order</Text>
              <Text style={styles.topInfo}>
                Ref # {invoiceData.sales_reference.name}
              </Text>
              <Text style={styles.topInfo}>Invoice # {invoiceData.so_no}</Text>
              <Text style={styles.topInfo}>Order Date: {invoiceData.date}</Text>
            </View>

            {/* Header Section */}
            <View
              style={{
                ...styles.column,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={styles.heading}>Office Copy</Text>
            </View>

            {/* Sales Order Info */}
            <View style={{ ...styles.column, ...styles.textRight }}>
              <Text style={styles.heading}>Customer</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.code}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.name}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.phone}</Text>
              <Text style={styles.topInfo}>{invoiceData.customer.address}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={{...styles.tableRow,...styles.borderBottom}}>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>#</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Category</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth3}}>
              <Text style={styles.tableHeading}>Size</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Product</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth1}}>
              <Text style={styles.tableHeading}>Grade</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Box</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Quantity</Text>
            </View>
            <View style={{...styles.tableCell,...styles.cellWidth2}}>
              <Text style={styles.tableHeading}>Squarefit</Text>
            </View>

          </View>
          {/* Sample Data */}
          {invoiceData.sales_order_items.map((item, index) => (
            <View style={{...styles.tableRow,...index % 2 === 0 ? styles.evenRow : styles.oddRow}}>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{index + 1}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.category.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth3}}>
                <Text style={styles.tableData}>{item.sub_category.measurement.cm_to_inch_sales_height}X{item.sub_category.measurement.cm_to_inch_sales_width}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.product.code}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth1}}>
                <Text style={styles.tableData}>{item.product.product_grade.name}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.box}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.quantity}</Text>
              </View>
              <View style={{...styles.tableCell,...styles.cellWidth2}}>
                <Text style={styles.tableData}>{item.squarefit}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { marginTop: 10 }]}>
            <View style={[styles.tableCell2, { flex: 2 }]}>
              <Text style={styles.tableData}>Line Total</Text>
            </View>

            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.box),
                    0
                  )).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.quantity),
                    0
                  )).toFixed(2)}</Text>
            </View>
            <View style={styles.tableCell2}>
              <Text style={styles.tableData}>{
                parseFloat(invoiceData.sales_order_items.reduce(
                    (total, item) => Number(total) + Number(item.squarefit),
                    0
                  )).toFixed(2)}</Text>
            </View>
          </View>
          



        </View>
        <view style={styles.signatureSection}>
          <View style={styles.signatureBlock2}>
            <Text>Carrier Name:...........</Text>
          </View>

          <View style={styles.signatureBlock2}>
            <Text>Carrier No: ...........</Text>
          </View>
          <View style={styles.signatureBlock2}>
            <Text>Carrier Phone:...........</Text>
          </View>
        </view>
        <View style={styles.signatureSection}>
          {/* Customer Signature */}
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Customer)</Text>
          </View>
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Stock In Charge)</Text>
          </View>

          {/* Shop Signature */}
          <View style={styles.signatureBlock2}>
            <View style={styles.signatureLine2} />
            <Text>Signature(Tiles Bajar)</Text>
          </View>
        </View>

        <View style={{ ...styles.row, ...styles.top20 }}>
          {/* Customer Info */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>
              {new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "Asia/Dhaka",
              })}
            </Text>
          </View>

          {/* Header Section */}
          <View style={styles.column}>
            <Text style={styles.topInfo}>{window.location.hostname}</Text>
          </View>

          {/* Sales Order Info */}
          <View style={{ ...styles.column, ...styles.textRight }}>
            <Text style={styles.topInfo}>{getUserDetails().name}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default CustomerInvoice;
