import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    PurchaseOrder,
    ProductUpdateURL,
    PurchaseNoSeries,
    PurchasePendingOrder,
    PurchaseRecieveOrder
} from "../helper/Config";

import {
    setPurchaseNoSeries,
    resetPurchaseFormValue,
    setPurchaseList,
    setPurchaseOrderById,
    setPurchasePendingList,
    resetPurchaseOrderById,
    resetPurchaseForm
} from '../../redux/slice/admin/PurchaseOrderSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "application/json"
    }
}


export async function PurchaseOrderCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseOrder
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + ProductUpdateURL + "/" + id + "?_method=PUT";
            method = "post";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetPurchaseFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function PurchaseOrderRecieveApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseRecieveOrder
        let method = 'post';
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetPurchaseOrderById())
            store.dispatch(resetPurchaseForm())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetPurchaseNoSeries() {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseNoSeries;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setPurchaseNoSeries(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetPurchaseOrderList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseOrder;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setPurchaseList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function GetPendingPurchaseOrderList() {
    try {
        store.dispatch(show())
        store.dispatch(resetPurchaseOrderById())
        let URL = BaseURL + PurchasePendingOrder;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setPurchasePendingList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function GetPurchaseOrderById(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseOrder + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setPurchaseOrderById(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}