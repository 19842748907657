import React, { Fragment, useEffect, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import store from "../../../redux/store/store";
import Select from 'react-select';
import {
  resetProductFormValue,
} from "../../../redux/slice/admin/ProductSlice";
import {
  onChangePurchaseFormInput
} from "../../../redux/slice/admin/PurchaseOrderSlice";
import {
  FillProductFormRequest,GetProductList
} from "../../apiServices/ProductApiService";

import {PurchaseOrderCreateApiService} from '../../apiServices/PurchaseOrderApiService'
import {
    GetUserList
  } from "../../apiServices/UserManagementApiService";
import { GetCategoryList } from "../../apiServices/CategoryApiService";
import { GetSupplierList } from "../../apiServices/SupplierApiService";
import { AiOutlineDelete} from "react-icons/ai";
import { GetBankList } from "../../apiServices/BankApiService";
import { GetPurchaseNoSeries } from "../../apiServices/PurchaseOrderApiService";
const PurchaseOrderCreate = () => {
  let navigate = useNavigate();
  let formValue = useSelector((state) => state.purchaseOrderSlice.formValue);
  let [rowRd, setRowID] = useState(0);
  let [productRowsInlineTable, setProductRowsInlineTable] = useState([]);
  let [paymentRowsInlineTable, setPaymentRowsInlineTable] = useState([]);
  let [measurement, setMeasurement] = useState({});
  let [supplierPreviousPayable, setSupplierPreviousPayable] = useState(0);

  useEffect(() => {
    (async () => {
      await GetProductList();
      await GetCategoryList();
      await GetSupplierList();
      await GetUserList();
      await GetBankList();
      await GetPurchaseNoSeries();
    })();
    store.dispatch(resetProductFormValue());
    let params = new URLSearchParams(window.location.search);
    let id = params.get("id");

    if (id !== null) {
      setRowID(id);
      (async () => {
        await FillProductFormRequest(id);
      })();
    } else {
      setRowID(0);
    }
  }, []);


  let productList=useSelector((state)=>(state.productSlice.productList.data));
  let userList=useSelector((state)=>(state.userManagementSlice.userList.data));
  let bankList=useSelector((state)=>(state.bankSlice.bankList.data));
  let noSeries=useSelector((state)=>(state.purchaseOrderSlice.purchaseNoSeries));
  let supplierList = useSelector(
    (state) => state.supplierSlice.supplierList.data
  );
  const saveChange = async () => {
    let dataObject = {
      'po_no': noSeries,
      'date': formValue.date,
      'supplier_id': formValue.supplier_id,
      'purchased_by': formValue.purchased_by,
      'note': formValue.noe,
      'total': "100",
      'comission': formValue.comission,
      'carrying_discount': formValue.carrying_discount,
      'carrying_charge': formValue.carrying_charge,
      'purchase_payable': parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_purchase || 0), 0).toFixed(2)),
      'previous_payable': supplierPreviousPayable.current_payable,
      'total_payable': parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_purchase || 0), 0).toFixed(2))+Number(supplierPreviousPayable.current_payable),
      'products': productRowsInlineTable,
      'payments': paymentRowsInlineTable
    }
    console.log(dataObject)
    

        

      if (await PurchaseOrderCreateApiService(dataObject, rowRd)) {
        navigate("/purchase/order/list");
      }
    
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Purchase Order",
    link: "/purchase/order/list",
    feature: rowRd !== 0 ? "Update Purchase" : "New Purchase Order",
  };
  const productListOptions = productList?.filter(item => {
  
  const isProductAlreadyAdded = productRowsInlineTable.some(
    (row) => row.product_id === item.id
  );

  
  return !isProductAlreadyAdded;
}).map((item) => ({
  value: item.id,
  label: `${item.code}`,
  category: item.category,
  subCategory: item.sub_category,
  salesPrice:item.sales_price,
  hasOfferPrice:item.has_offer_price,
  offerPrice:item.offer_price
}));
const [categoryState, setCategoryState] = useState([]);
const [subCategoryState, setSubCategoryState] = useState([]);
const handleProductChange=(index,selectedOption)=>{
    console.log(selectedOption)
    handleChangeInput(index, "product_id", selectedOption.value)
    handleChangeInput(index, "category_id", selectedOption.category.id)
    handleChangeInput(index, "sub_category_id", selectedOption.subCategory.id)
    setCategoryState((prevArray) => [...prevArray, selectedOption.category]);
    setSubCategoryState((prevArray) => [...prevArray, selectedOption.subCategory]);
    handleChangeInput(index, "height", selectedOption.subCategory.measurement.height);
    handleChangeInput(index, "width", selectedOption.subCategory.measurement.width);
    handleChangeInput(index, "per_box_count", selectedOption.subCategory.measurement.per_box_count);
    handleChangeInput(index, "sft_purchase", selectedOption.subCategory.measurement.sft_purchase);
    handleChangeInput(index, "sft_sales", selectedOption.subCategory.measurement.sft_sales);
    handleChangeInput(index, "measurement_sft_purchase", selectedOption.subCategory.measurement.sft_purchase);
    handleChangeInput(index, "measurement_sft_sales", selectedOption.subCategory.measurement.sft_sales);

  }



  const isDateValid = true;
  const isPurchasedByValid = true;
  const isSupplierValid = true;
  const isComissionValid = true;
  const isCarryingDiscountValid = true;
  const isCarryingChargeValid = true;




  const handleDeleteRow = (index) => {

    let data = productRowsInlineTable.filter((_, i) => i !== index)
    setProductRowsInlineTable(data);

  };
  const addNewRow = () => {
    setProductRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        category_id: "",
        sub_category_id: "",
        product_id: "",
        note: "",
        box: "",
        quantity: "",
        purchase_price: "",
        sales_price: "",
        sft_purchase: "",
        price_purchase: "",
        sft_sales: "",
        price_sales: "",
        commision: "",
        height: "",
        width: "",
        per_box_count:"",
        measurement_sft_purchase:"",
        measurement_sft_sales:"",
        discount:"",
      },
    ]);
    
  };
  const handleChangeInput = (index, field, value) => {
    setProductRowsInlineTable((prevRows) =>
      prevRows.map((row, i) =>
         i === index ? { ...row, [field]: value } : row
      )
    );
  };

const handleChangeInputPurchasePrice=(index, field, value)=>{
  handleChangeInput(index, field, value)
  let sales_price =Number(Number(measurement.sft_sales)/Number(measurement.sft_purchase))*Number(value)

  let price_purchase =Number(productRowsInlineTable[index].sft_purchase)*Number(value)
  handleChangeInput(index, 'sales_price', sales_price.toFixed(2))
  handleChangeInput(index, 'price_purchase', price_purchase.toFixed(2))
  let price_sales =Number(productRowsInlineTable[index].sft_sales)*Number(sales_price)
  handleChangeInput(index, 'price_sales', price_sales.toFixed(2))
  

}
const handleChangeDiscountInput=(index, field, value)=>{
  handleChangeInput(index,field,value)
  let price_purchase =Number(productRowsInlineTable[index].price_purchase)-Number(value)
  handleChangeInput(index, 'price_purchase', price_purchase.toFixed(2))

}
const handleChangeInputBox=(index, field, value)=>{
  handleChangeInput(index, field, value)
  let totalQuantity = Number(Number(value)*Number(productRowsInlineTable[index].per_box_count))
  if(Number(productRowsInlineTable[index].quantity)>0){
    totalQuantity = Number(totalQuantity)+Number(productRowsInlineTable[index].quantity)
  }
  let sft_purchase =Number(totalQuantity)*Number(productRowsInlineTable[index].measurement_sft_purchase)
  handleChangeInput(index, 'sft_purchase', sft_purchase.toFixed(2))
  let sft_sales =Number(totalQuantity)*Number(productRowsInlineTable[index].measurement_sft_sales)
  handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))

  // let sft_sales =Number(Number(measurement.per_box_count)*Number(measurement.sft_sales))*Number(value)
  // handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))
  // if(productRowsInlineTable[index].purchase_price>0){
  //   let price_purchase =Number(sft_purchase)*Number(productRowsInlineTable[index].purchase_price)
  //   handleChangeInput(index, 'price_purchase', price_purchase.toFixed(2))
    
  // }

  // if(productRowsInlineTable[index].sales_price>0){
  //   let price_sales =Number(productRowsInlineTable[index].sales_price)*Number(sft_sales)
  //   handleChangeInput(index, 'price_sales', price_sales.toFixed(2))
  // }


  //if(productRowsInlineTable[index].quantity>0){
   // sft_purchase= sft_purchase+Number(Number(productRowsInlineTable[index].quantity)*Number(measurement.sft_purchase))
    //handleChangeInput(index, 'sft_purchase', sft_purchase.toFixed(2))

    //  sft_sales = sft_sales+Number(Number(productRowsInlineTable[index].quantity)*Number(measurement.sft_sales))
    // handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))

    // if(productRowsInlineTable[index].sales_price>0){
    //   let price_sales =Number(productRowsInlineTable[index].sales_price)*Number(sft_sales)
    //   handleChangeInput(index, 'price_sales', price_sales.toFixed(2))
    // }
    
  //}

  
}

const handleChangeQuantityInput=(index,field,value)=>{
  
  if(Number(productRowsInlineTable[index].per_box_count)<=value){
    handleChangeInput(index,field,0)
    handleChangeInputBox(index,'box',Number(productRowsInlineTable[index].box)+1)

    let totalQuantity = Number(Number(Number(productRowsInlineTable[index].box)+1)*Number(productRowsInlineTable[index].per_box_count))
    let sft_purchase =Number(totalQuantity)*Number(productRowsInlineTable[index].measurement_sft_purchase)
    handleChangeInput(index, 'sft_purchase', sft_purchase.toFixed(2))

    let sft_sales =Number(totalQuantity)*Number(productRowsInlineTable[index].measurement_sft_sales)
    handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))
  }else{
    handleChangeInput(index,field,value)
    let totalQuantity = Number(productRowsInlineTable[index].box)*Number(productRowsInlineTable[index].per_box_count)+Number(value)
    // let sft_purchase= Number(productRowsInlineTable[index].sft_purchase)+Number(totalQuantity)

    // handleChangeInput(index, 'sft_purchase', sft_purchase.toFixed(2))
    let sft_purchase= Number(productRowsInlineTable[index].measurement_sft_purchase)*Number(totalQuantity)

    handleChangeInput(index, 'sft_purchase', sft_purchase.toFixed(2))

    let sft_sales =Number(totalQuantity)*Number(productRowsInlineTable[index].measurement_sft_sales)
    handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))

    //  let sft_sales = Number(productRowsInlineTable[index].sft_sales)+Number(Number(value)*Number(measurement.sft_sales))
    // handleChangeInput(index, 'sft_sales', sft_sales.toFixed(2))

    // if(productRowsInlineTable[index].sales_price>0){
    //   let price_sales =Number(productRowsInlineTable[index].sales_price)*Number(sft_sales)
    //   handleChangeInput(index, 'price_sales', price_sales.toFixed(2))
    // }
  }
}

const handleChangeInputSales=(index, field, value)=>{
  handleChangeInput(index, field, value)
  let price_sales =Number(value)*Number(productRowsInlineTable[index].sft_sales)
    handleChangeInput(index, 'price_sales', price_sales.toFixed(2))
}

  
  const handleDeletePaymentRow = (index) => {

    let data = paymentRowsInlineTable.filter((_, i) => i !== index)
    setPaymentRowsInlineTable(data);

  };
  const addNewPaymentRow = () => {
    setPaymentRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        date: "",
        payment_method: "",
        bank_id: "",
        note: "",
        paid: "",
        discount: "",
        due: ""
      },
    ]);
    
  };
  const handleChangePaymentInput = (index, field, value) => {
    setPaymentRowsInlineTable((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      )
    );
  };

  const handleChangeSupplier=(field,value)=>{
    setSupplierPreviousPayable(value)
    store.dispatch(
      onChangePurchaseFormInput({
        name: field,
        value: value.id,
      })
    );
  }
  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2">
                <h5 class="mb-0">
                  {breadcumb.feature}{" "}
                  <span className="text-success border">{noSeries}</span>
                </h5>
              </div>
              <div className="card-body">
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Date
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control ${
                            !isDateValid ? "is-invalid" : ""
                          }`}
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "date",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.date}
                          type="date"
                        />
                        {!isDateValid && (
                          <div className="invalid-feedback">
                            Date is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Supplier
                      </label>

                      <div className="col-lg-9">
                        <select
                          onChange={(e) => {
                            handleChangeSupplier('supplier_id',JSON.parse(e.target.value))
                            
                          }}
                          className={`form-control admin-form-control ${
                            !isSupplierValid ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Choose One</option>
                          {supplierList?.map((item) => (
                            <option
                              key={item.id}
                              selected={formValue.supplier_id === item.id}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {!isSupplierValid && (
                          <div className="invalid-feedback">
                            Supplier is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Purchase By
                      </label>

                      <div className="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "purchased_by",
                                value: e.target.value,
                              })
                            );
                            
                          }}
                          className={`form-control admin-form-control ${
                            !isPurchasedByValid ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Choose One</option>
                          {userList?.map((item) => (
                            <option
                              key={item.id}
                              selected={formValue.purchased_by === item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {!isPurchasedByValid && (
                          <div className="invalid-feedback">
                            Purchase by is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Note
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="note"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "note",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.note}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4" style={{width:"20%"}}>Product</th>
                              <th class="col-md-4">Category</th>
                              <th class="col-md-4">SubCategory</th>
                              <th class="col-md-1">Box</th>
                              <th class="col-md-1">Quantity</th>
                              <th class="col-md-2">Purchase Price(Per SFT)</th>
                              <th class="col-md-2">Sales Price</th>
                              <th class="col-md-2">Sft(Purchase)</th>
                              <th class="col-md-2">Price(Purchase)</th>
                              <th class="col-md-2">Sft(Sale)</th>
                              <th class="col-md-2">Price(Sale)</th>
                              <th class="col-md-2">Line Discount</th>
                              <th class="col-md-2">Commision</th>
                              <th class="col-md-2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            productRowsInlineTable.map((row, index) => (
                                <tr key={index}>
                                <td>
                                <Select
                                value={productListOptions.find(option => option.value === row.product_id)}
                                onChange={(selectedOption) => handleProductChange(index,selectedOption)}
                                options={productListOptions}
                                isSearchable={true}
                                placeholder="Choose One"
                                menuPortalTarget={document.body}
                               
                              />
                  
                              </td>
                              <td>
                              <select
                                  disabled={true}
                                  className={`form-control admin-form-control`}
                                  value={row.category_id}
                                >
                                  
                                  {categoryState?.map((item) => (
                                    <option
                                      key={item.id}
                                      selected={
                                        row.category_id === item.id
                                      }
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                              <select
                                  disabled={true}
                                  className={`form-control admin-form-control }`}
                                  
                                  value={row.sub_category_id}
                                >
                                  
                                  {subCategoryState?.map((item) => (
                                    <option  key={item.id}
                                    selected={
                                      row.sub_category_id === item.id
                                    }  value={item.id}>{item.measurement.height} X {item.measurement.width} ({item.measurement.per_box_count} per/box)</option>
                                  ))}
                                </select>
                              </td>
                              

                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.box}
                                  type="number"
                                  onChange={(e)=>handleChangeInputBox(index,"box",e.target.value)}
                                  min="0"
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.quantity}
                                  type="number"
                                  onChange={(e)=>handleChangeQuantityInput(index,"quantity",e.target.value)}
                                  min="0"
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.purchase_price}
                                  type="number"
                                  onChange={(e)=>handleChangeInputPurchasePrice(index,"purchase_price",e.target.value)}
                                  step="1"
                                  min="0"
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.sales_price}
                                  type="number"
                                  onChange={(e)=>handleChangeInputSales(index,"sales_price",e.target.value)}
                                  step="1"
                                  min="0"
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.sft_purchase}
                                  type="number"
                                  onChange={(e)=>handleChangeInput(index,"sft_purchase",e.target.value)}
                                  step="1"
                                  min="0"
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.price_purchase}
                                  type="number"
                                  onChange={(e)=>handleChangeInput(index,"price_purchase",e.target.value)}
                                  step="1"
                                  min="0"
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.sft_sales}
                                  type="number"
                                  onChange={(e)=>handleChangeInput(index,"sft_sales",e.target.value)}
                                  step="1"
                                  min="0"
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.price_sales}
                                  type="number"
                                  onChange={(e)=>handleChangeInput(index,"price_sales",e.target.value)}
                                  step="1"
                                  min="0"
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.discount}
                                  type="number"
                                  onChange={(e)=>handleChangeDiscountInput(index,"discount",e.target.value)}
                                  step="1"
                                  min="0"
                                  
                                />
                              </td>
                              <td>
                                <input type="checkbox"/>
                              </td>
                              <td>
                                <AiOutlineDelete onClick={() => handleDeleteRow(index)} size={15}  className="action-icon"/>
                              </td>
                            </tr>
                            ))
  
                        }
                            
                            <tr>
                              
                              <td colSpan={14} className="text-center" onClick={addNewRow}>
                                <button className="btn btn-sm btn-default" >Add New</button>
                              </td>
                              
                            </tr>
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.box || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.quantity || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.purchase_price || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.sales_price || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.sft_purchase || 0), 0).toFixed(2))}</th>
                              <th class="col-md-4">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_purchase || 0), 0).toFixed(2))}</th>
                              <th class="col-md-4">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.sft_sales || 0), 0).toFixed(2))}</th>
                              <th class="col-md-4">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_sales || 0), 0).toFixed(2))}</th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Commission
                      </label>
                      
                      
                      
                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control ${
                            !isComissionValid ? "is-invalid" : ""
                          }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "comission",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.comission}
                          type="number"
                        />
                        {!isComissionValid && (
                          <div className="invalid-feedback">
                            Commission is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Carrying Discount
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control ${
                            !isCarryingDiscountValid ? "is-invalid" : ""
                          }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "carrying_discount",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.carrying_discount}
                          type="number"
                        />
                        {!isCarryingDiscountValid && (
                          <div className="invalid-feedback">
                            Carrying Discount is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Carrying Charge
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control ${
                            !isCarryingChargeValid ? "is-invalid" : ""
                          }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "carrying_charge",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.carrying_charge}
                          type="number"
                        />
                        {!isCarryingChargeValid && (
                          <div className="invalid-feedback">
                            Carrying Charge is required.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Purchase Payable
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "purchase_payable",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={parseFloat((productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_purchase || 0), 0)-Number(formValue.comission)-Number(formValue.carrying_discount)+Number(formValue.carrying_charge)).toFixed(2))}
                          type="number"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Previous Payable
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "previous_payable",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={supplierPreviousPayable.current_payable}
                          type="number"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Total Payable
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseFormInput({
                                name: "total_payable",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.price_purchase || 0), 0).toFixed(2))+Number(supplierPreviousPayable.current_payable)-Number(formValue.comission)-Number(formValue.carrying_discount)+Number(formValue.carrying_charge)}
                          type="number"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4">Payment Method</th>
                              <th class="col-md-4">Bank</th>
                              <th class="col-md-2">Date</th>
                              <th class="col-md-2">Paid</th>
                              <th class="col-md-2">Discount</th>
                              <th class="col-md-2">Due</th>
                              <th class="col-md-2">Note</th>
                              <th class="col-md-2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            paymentRowsInlineTable.map((row, index) => (

                              <tr key={index}>
                                <td>
                                  <select
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,'payment_method',e.target.value);
                                    }}
                                    className={`form-control admin-form-control`}
                                    value={row.payment_method}
                                  >
                                    <option value="">Choose One</option>
                                    
                                      <option
                                
                                        selected={
                                          row.payment_method === "Bank"
                                        }
                                        value="Bank"
                                      >
                                        Bank
                                      </option>
                                      <option
                                
                                      selected={
                                        row.payment_method === "Cash"
                                      }
                                      value="Cash"
                                      >
                                      Cash
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <select
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,'bank_id',e.target.value);
                                    }}
                                    className={`form-control admin-form-control }`}
                                    value={row.id}
                                  >
                                    <option value="">Choose One</option>
                                    {bankList?.map((item) => (
                                      <option
                                        key={item.id}
                                        selected={
                                          row.bank_id === item.id
                                        }
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                <input
                                className={`form-control admin-form-control`}
                                onChange={(e) => {
                                  handleChangePaymentInput(index,"date",e.target.value)
                                }}
                                value={row.date}
                                type="date"
                              />
                                </td>

                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,"paid",e.target.value)
                                    }}
                                    value={row.paid}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,"discount",e.target.value)
                                    }}
                                    value={row.discount}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,"due",e.target.value)
                                    }}
                                    value={row.due}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,"note",e.target.value)
                                    }}
                                    value={row.note}
                                    type="text"
                                  />
                                </td>
                            
                                <td>
                                  <AiOutlineDelete onClick={() => handleDeletePaymentRow(index)} size={15}  className="action-icon"/>
                                </td>
                            </tr>
                      
                            ))
                          }
                          <tr>
                              
                              <td colSpan={10} className="text-center" onClick={addNewPaymentRow}>
                                <button className="btn btn-sm btn-default" >Add New</button>
                              </td>
                              
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0)}</th>
                              <th class="col-md-2">{paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.discount || 0), 0)}</th>
                              <th class="col-md-2">{paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.due || 0), 0)}</th>
                              <th class="col-md-2"></th>
                              <th class="col-md-2"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PurchaseOrderCreate;
