import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeBankFormInput, resetBankFormValue } from '../../../redux/slice/admin/BankSlice';
import { BankCreateApiService,FillBankFormRequest,GetBankTypeList } from '../../apiServices/BankApiService';

const BankCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.bankSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetBankTypeList();
        })();
        store.dispatch(resetBankFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillBankFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let bankAccountTypeList=useSelector((state)=>(state.bankSlice.bankAccountTypeList.data));
    // console.log(userRoleList)
    const saveChange =  async() => {
        if(isEmpty(formValue.bank_account_type_id)){
            errorToast("Bank account type required !")
        }
        else if(isEmpty(formValue.code)){
            errorToast("Code Required !")
        }else if(isEmpty(formValue.name)){
            errorToast("Name Required !")
        }else if(isEmpty(formValue.branch)){
            errorToast("Branch Required !")
        }else if(isEmpty(formValue.account_name)){
            errorToast("Account Name Required !")
        }else if(isEmpty(formValue.account_no)){
            errorToast("Account No Required !")
        }else if(isEmpty(formValue.address)){
            errorToast("Address Required !")
        }else {
            console.log(formValue)
            if(await BankCreateApiService(formValue,rowRd)){
                navigate("/bank/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Account",
        link:"/bank/list",
        feature:rowRd !==0?"Update Bank":"Create Bank"
    }
    const isNameValid = true;
    const isCodeValid = true;
    const isBranchValid = true;
    const isAccountNameValid = true;
    const isAccountNoValid = true;
    const isBankAccountTypeValid = true;
    const isAddressValid = true;


    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Code</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isCodeValid ? 'is-invalid' : '' }`} placeholder="code" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"code",value:e.target.value}))}} value={formValue.code} type="text" />
                                        {!isCodeValid && (
                                            <div className="invalid-feedback">Code is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Bank Name</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isNameValid ? 'is-invalid' : '' }`} placeholder="bank name" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                        {!isNameValid && ( 
                                            <div className="invalid-feedback">Bank name is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Branch</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isBranchValid ? 'is-invalid' : '' }`} placeholder="branch" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"branch",value:e.target.value}))}} value={formValue.branch} type="text" />
                                    {!isBranchValid && ( 
                                            <div className="invalid-feedback">Branch is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Account Type</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"bank_account_type_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isBankAccountTypeValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            bankAccountTypeList?.map(item=>
                                                <option  key={item.id} selected={formValue.bank_account_type_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isBankAccountTypeValid && (
                                            <div className="invalid-feedback">Bank AccountType is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Account Name</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isAccountNameValid ? 'is-invalid' : '' }`} placeholder="account name" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"account_name",value:e.target.value}))}} value={formValue.account_name} type="email" />
                                    {!isAccountNameValid && ( 
                                            <div className="invalid-feedback">AccountName is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Account No</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                                !isAccountNoValid ? 'is-invalid' : '' }`} placeholder="account no" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"account_no",value:e.target.value}))}} value={formValue.account_no} type="email" />
                                        {!isAccountNoValid && ( 
                                                <div className="invalid-feedback">AccountNo is required.</div>
                                              )}

                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Opening Balance</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="opening balance" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"opening_balance",value:e.target.value}))}} value={formValue.opening_balance} type="number"
                                            step="0.01" />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Current Balance</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="cuurent balance" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"current_balance",value:e.target.value}))}} value={formValue.current_balance} type="number"
                                            step="0.01" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Note</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="note" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"note",value:e.target.value}))}} value={formValue.note} type="text" />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Address</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="address" onChange={(e)=>{store.dispatch(onChangeBankFormInput({name:"address",value:e.target.value}))}} value={formValue.address} type="text" />
                                        {!isAddressValid && (
                                            <div className="invalid-feedback">Address is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default BankCreate;