import { createSlice } from '@reduxjs/toolkit'
export const SalesOrderSlice = createSlice({
    name: 'sales',
    initialState: {
        salesList: [],
        dueSalesListByCustomer: [],
        customerTypeList: [],
        customerList: [],
        salesPendingList: [],
        salesOrderById: {},
        salesNoSeries: "",
        customerNoSeries: "",
        salesForm: {
            'po_no': '',
            'date': '',
            'recieved_by': ''
        },
        formValue: {
            'so_no': "",
            'date': "",
            'customer_id': "",
            'sales_reference': '',
            'note': "",
            'total': "",
            'sub_total': "",
            'discount': "",
            'carrying_charge': "",
            'labour_charge': "",
            'vat': "",
            'products': [],
            'payments': []
        }
    },

    reducers: {
        salesForm: (state, action) => {
            state.salesForm[`${action.payload.name}`] = action.payload.value;
        },
        onChangeSalesFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetSalesFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => {
                if (state.formValue[i] === 'products' || state.formValue[i] === 'payments') {
                    return state.formValue[i] = []
                } else {
                    return state.formValue[i] = ""
                }

            });
        },
        setSalesList: (state, action) => {
            state.salesList = action.payload
        },
        setDueSalesListByCustomer: (state, action) => {
            state.dueSalesListByCustomer = action.payload

        },
        setCustomerTypeList: (state, action) => {
            state.customerTypeList = action.payload
        },
        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },
        setSalesPendingList: (state, action) => {
            state.salesPendingList = action.payload
        },
        setSalesOrderById: (state, action) => {
            state.salesOrderById = action.payload
        },
        setSalesNoSeries: (state, action) => {
            state.salesNoSeries = action.payload
        },

        setCustomerNoSeries: (state, action) => {
            state.customerNoSeries = action.payload
        },
        resetSalesOrderById: (state, action) => {
            state.salesOrderById = {}
        },
        resetSalesForm: (state, action) => {
            Object.keys(state.salesForm).forEach((i) => {
                return state.salesForm[i] = ""
            });
        }

    },
})

export const { setCustomerList, setCustomerTypeList, setCustomerNoSeries, onChangeSalesFormInput, resetSalesFormValue, setSalesList, setSalesNoSeries, setSalesOrderById, setSalesPendingList, resetSalesOrderById, onChangeSalesReciveFormInput, resetSalesForm, setDueSalesListByCustomer } = SalesOrderSlice.actions

export default SalesOrderSlice.reducer