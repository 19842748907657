import { createSlice } from '@reduxjs/toolkit'

export const BankSlice = createSlice({
    name: 'bank',
    initialState: {
        bankAccountTypeList: [],
        bankList: [],
        cashBalance: 0,
        formValue: {
            bank_account_type_id: "",
            code: "",
            name: "",
            branch: "",
            account_name: "",
            account_no: "",
            opening_balance: "",
            current_balance: "",
            note: "",
            address: "",
        }
    },
    reducers: {

        onChangeBankFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetBankFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setBankList: (state, action) => {
            state.bankList = action.payload
        },
        setBankAccountTypeList: (state, action) => {
            state.bankAccountTypeList = action.payload
        },
        setCashBalance: (state, action) => {
            state.cashBalance = action.payload
        }

    },
})

export const { setCashBalance, onChangeBankFormInput, resetBankFormValue, setBankList, setBankAccountTypeList } = BankSlice.actions

export default BankSlice.reducer