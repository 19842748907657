import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Select from "react-select";
import { AiOutlineDelete } from "react-icons/ai";
import {
  GetSalesOrderById,
  GetDueSalesOrderByCustomerId,
  SalesOrderReturnApiService
} from "../../apiServices/SalesOrderApiService";

const SalesOrderReturn = () => {
  let [productRowsInlineTable, setProductRowsInlineTable] = useState([]);
  let [dueRowsInlineTable, setDueRowsInlineTable] = useState([]);
  const { id, customerId } = useParams();
  useEffect(() => {
    console.log(id);
    
    if (id !== null && customerId !== null) {
      (async () => {
        await GetSalesOrderById(id);
        await GetDueSalesOrderByCustomerId(id, customerId);
      })();
    }

  }, []);

  let soData = useSelector((state) => state.salesOrderSlice.salesOrderById);
  let dueList = useSelector(
    (state) => state.salesOrderSlice.dueSalesListByCustomer
  );
  let navigate = useNavigate();

  const saveChange = async () => {
    let amount = parseFloat(
      (
        Number(
          productRowsInlineTable.reduce(
            (acc, obj) => acc + parseFloat(obj.total || 0),
            0
          )-dueRowsInlineTable.reduce(
            (acc, obj) => acc + parseFloat(obj.paid || 0),
            0
          ) -
          Number(soData && soData.due)
        ).toFixed(2)
      )
    ).toFixed(2);
    let amountReturnedTotal = parseFloat(
      (
        Number(
          productRowsInlineTable.reduce(
            (acc, obj) => acc + parseFloat(obj.total || 0),
            0
          )
        ).toFixed(2)
      )
    ).toFixed(2);
    console.log(dueRowsInlineTable)
    let payload = {
      so_no:soData && soData.so_no,
      return_amount:amount<0?0:amount,
      total_return_amount:amountReturnedTotal,
      due:amount>0?0:Math.abs(amount),
      products:productRowsInlineTable,
      payments:dueRowsInlineTable
    }
    console.log(payload)
    if(await SalesOrderReturnApiService(payload)){
      const viewSalesOrderRoute = `/sales/order/${id}`
        navigate(viewSalesOrderRoute);
    }
    
    
  };
  const handleChangeInputBox = (index, field, value) => {
    handleChangeInput(index, field, value);
    let squarefit =
      Number(
        Number(productRowsInlineTable[index].sft_sales) * Number(productRowsInlineTable[index].per_box_count)
      ) * Number(value);
    if (value <= 0 || isNaN(value)) {
      squarefit = 0;
    }
    if (productRowsInlineTable[index].quantity >= 0) {
      squarefit =
        squarefit +
        Number(
          Number(productRowsInlineTable[index].sft_sales) *
            Number(productRowsInlineTable[index].quantity)
        );
    }

    handleChangeInput(index, "squarefit", squarefit.toFixed(2));
    if (productRowsInlineTable[index].rate_per_squarefit) {
      let total =
        Number(squarefit.toFixed(2)) *
        Number(productRowsInlineTable[index].rate_per_squarefit);
      handleChangeInput(index, "total", total.toFixed(2));
    }
    
  };
  const handleChangeQuantityInput = (index, field, value) => {
    console.log(value);
    handleChangeInput(index, field, value);
    let squarefit =
      Number(
        Number(productRowsInlineTable[index].sft_sales) * Number(productRowsInlineTable[index].per_box_count)
      ) *
        Number(productRowsInlineTable[index].box) +
      Number(Number(productRowsInlineTable[index].sft_sales) * Number(value));
    if (
      Number(productRowsInlineTable[index].box) <= 0 ||
      isNaN(productRowsInlineTable[index].box)
    ) {
      squarefit = Number(Number(productRowsInlineTable[index].sft_sales) * Number(value));
    }
    if (
      (Number(value) <= 0 || isNaN(value)) &&
      productRowsInlineTable[index].box > 0
    ) {
      squarefit =
        Number(
          Number(productRowsInlineTable[index].sft_sales) * Number(productRowsInlineTable[index].per_box_count)
        ) * Number(productRowsInlineTable[index].box);
    }
    handleChangeInput(index, "squarefit", squarefit.toFixed(2));

    if (Number(value) >= Number(productRowsInlineTable[index].per_box_count)) {
      let res = Number(value) / Number(productRowsInlineTable[index].per_box_count);
      let remainder = Number(value) % Number(productRowsInlineTable[index].per_box_count);
      let totalBox = Number(productRowsInlineTable[index].box) + Number(res);
      handleChangeInput(index, "box", Math.floor(totalBox));
      handleChangeInput(index, "quantity", remainder);
    }

    if (productRowsInlineTable[index].rate_per_squarefit) {
      let total =
        Number(squarefit) *
        Number(productRowsInlineTable[index].rate_per_squarefit);
      handleChangeInput(index, "total", total.toFixed(2));
    }

  };

  const productListOptions =
    soData &&
    soData.sales_order_items
      ?.filter((item) => {
        const isProductAlreadyAdded = productRowsInlineTable.some(
          (row) => row.product_id === item.product_id
        );

        return !isProductAlreadyAdded;
      })
      .map((item) => ({
        value: item.product_id,
        label: `${item.product.name} (${item.product.code})`,
        category: item.category,
        subCategory: item.sub_category,
        box: item.box,
        quantity: item.quantity,
        squarefit: item.squarefit,
        rate_per_squarefit: item.rate_per_squarefit,
        total: item.total,
      }));

  const [categoryState, setCategoryState] = useState([]);
  const [subCategoryState, setSubCategoryState] = useState([]);
  const handleChangeInput = (index, field, value) => {
    setProductRowsInlineTable((prevRows) =>
      prevRows.map((row, i) => (i === index ? { ...row, [field]: value } : row))
    );
  };

  const handleProductChange = (index, selectedOption) => {
    
    handleChangeInput(index, "product_id", selectedOption.value);
    handleChangeInput(index, "category_id", selectedOption.category.id);
    handleChangeInput(index, "sub_category_id", selectedOption.subCategory.id);
    handleChangeInput(index, "box", selectedOption.box);
    handleChangeInput(index, "quantity", selectedOption.quantity);
    handleChangeInput(index, "squarefit", selectedOption.squarefit);
    handleChangeInput(index, "max_squarefit", selectedOption.squarefit);
    handleChangeInput(index, "sft_sales", selectedOption.subCategory.measurement.sft_sales);
    handleChangeInput(index, "per_box_count", selectedOption.subCategory.measurement.per_box_count);
    handleChangeInput(index, "sft_purchase", selectedOption.subCategory.measurement.sft_purchase);

    
    handleChangeInput(
      index,
      "rate_per_squarefit",
      selectedOption.rate_per_squarefit
    );
    handleChangeInput(index, "total", selectedOption.total);
    setCategoryState((prevArray) => [...prevArray, selectedOption.category]);
    setSubCategoryState((prevArray) => [
      ...prevArray,
      selectedOption.subCategory,
    ]);
    // setMeasurement(selectedOption.subCategory.measurement);
    
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Sales Order Return",
    link: "/sales/order/list",
    feature: "Sales Order Return",
  };

  const addNewRow = () => {
    setProductRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        category_id: "",
        sub_category_id: "",
        product_id: "",
        note: "",
        box: "",
        quantity: "",
        squarefit: "",
        max_squarefit: "",
        rate_per_squarefit: "",
        total: "",
        sft_sales: "",
        per_box_count: "",
        sft_purchase: "",
      },
    ]);
  };
  const handleDeleteRow = (index) => {
    let data = productRowsInlineTable.filter((_, i) => i !== index);
    setProductRowsInlineTable(data);
  };

  const handleCheckboxChange=(index,item)=>{
    if(dueRowsInlineTable.some(obj => obj.so_no === index)){
      let data = dueRowsInlineTable.filter((row, i) => row.so_no !== index);
      setDueRowsInlineTable(data);
    }else{
      setDueRowsInlineTable((prevRows) => [
        ...prevRows,
        {
          so_no: item.so_no,
          sales_order_id: item.id,
          payment_method: "Cash",
          date: (new Date()).toISOString().split('T')[0],
          paid: item.due
        },
      ]);
    }
    

    
  }
  const handleChangeReturnAmountInput = (index, field, value,due) => {
    setDueRowsInlineTable((prevRows) =>
      prevRows.map((row, i) => (row.so_no === index && Number(due)>=Number(value) ? { ...row, [field]: value } : row))
    );
  }; 

  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2 ">
                <h5 class="mb-0 w-100 d-flex justify-content-between align-items-center">
                  <div>
                    {breadcumb.feature}{" "}
                    <span className="text-success border">
                      {id !== null ? id : ""}
                    </span>
                    <span className="text-success border"></span>
                  </div>
                </h5>
              </div>
              <div className="card-body">
                <div className=" mt-1">
                  <div class="row mt-2">
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Sales Date:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{soData && soData.date}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Customer:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{soData && soData.customer?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Reference:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{soData && soData.sales_reference?.name}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Status:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{soData && soData.status}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-lg-6">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Note:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{soData && soData.note}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <h6 className="underlined">Product to be returned:</h6>
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-3" style={{ width: "15%" }}>
                                Product
                              </th>
                              <th class="col-md-4">Category</th>
                              <th class="col-md-4">SubCategory</th>
                              <th class="col-md-2">Box</th>
                              <th class="col-md-2">Quantity</th>
                              <th class="col-md-2">SquareFit</th>
                              <th class="col-md-2">Rate/Sft</th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-1">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productRowsInlineTable.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <Select
                                    value={productListOptions.find(
                                      (option) =>
                                        option.value === row.product_id
                                    )}
                                    onChange={(selectedOption) =>
                                      handleProductChange(index, selectedOption)
                                    }
                                    options={productListOptions}
                                    isSearchable={true}
                                    placeholder="Choose One"
                                    menuPortalTarget={document.body}
                                  />
                                </td>
                                <td>
                                  <select
                                    disabled={true}
                                    className={`form-control admin-form-control`}
                                    value={row.category_id}
                                  >
                                    {categoryState?.map((item) => (
                                      <option
                                        key={item.id}
                                        selected={row.category_id === item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    disabled={true}
                                    className={`form-control admin-form-control }`}
                                    value={row.sub_category_id}
                                  >
                                    {subCategoryState?.map((item) => (
                                      <option
                                        key={item.id}
                                        selected={
                                          row.sub_category_id === item.id
                                        }
                                        value={item.id}
                                      >
                                        {item.measurement.height} X{" "}
                                        {item.measurement.width} (
                                        {item.measurement.per_box_count}{" "}
                                        per/box)
                                      </option>
                                    ))}
                                  </select>
                                </td>

                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    value={row.box}
                                    type="number"
                                    onChange={(e) =>
                                      handleChangeInputBox(
                                        index,
                                        "box",
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                    step="1"
                                    min="0"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    value={row.quantity}
                                    type="number"
                                    step="1"
                                    min="0"
                                    onChange={(e) =>
                                      handleChangeQuantityInput(
                                        index,
                                        "quantity",
                                        parseInt(e.target.value, 10)
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    value={row.squarefit}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    value={row.rate_per_squarefit}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    value={row.total}
                                    type="number"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <AiOutlineDelete
                                    onClick={() => handleDeleteRow(index)}
                                    size={15}
                                    className="action-icon"
                                  />
                                </td>
                              </tr>
                            ))}

                            {soData &&
                              soData.sales_order_items &&
                              soData.sales_order_items.length >
                                productRowsInlineTable.length && (
                                <tr>
                                  <td
                                    colSpan={9}
                                    className="text-center"
                                    onClick={addNewRow}
                                  >
                                    <button className="btn btn-sm btn-default">
                                      Add New
                                    </button>
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="row mt-5">
                  <div class="col-lg-12 ">
                    <div class="row form-row">
                      <div className="col-md-6">
                        <p>Sub Total: {soData && soData.sub_total}</p>
                        <p>Discount: {soData && soData.discount}</p>
                        <p>
                          Carrying Charge: {soData && soData.carrying_charge}
                        </p>
                        <p>Labour Charge: {soData && soData.labour_charge}</p>
                        <p>VAT(%): {soData && soData.vat}</p>
                        <p>Total: {soData && soData.total}</p>
                        <p>Paid: {soData && soData.paid}</p>
                        
                      </div>
                      <div className="col-md-6">
                      {(() => {
                        const totalReturnAmount = parseFloat(
                          (
                            Number(
                              productRowsInlineTable.reduce(
                                (acc, obj) => acc + parseFloat(obj.total || 0),
                                0
                              )-dueRowsInlineTable.reduce(
                                (acc, obj) => acc + parseFloat(obj.paid || 0),
                                0
                              ) -
                              Number(soData && soData.due)
                            ).toFixed(2)
                          )
                        ).toFixed(2);
                
                        return (
                          <>
                          <h6>Total Return Amount: {parseFloat(
                            productRowsInlineTable
                              .reduce(
                                (acc, obj) =>
                                  acc + parseFloat(obj.total || 0),
                                0
                              )
                              .toFixed(2)
                          )}</h6>
                          <p>Invoice Due: {soData && soData.due}</p>

                          {
                            totalReturnAmount > 0 ? <p className="bold text-success">Remaing Return Amount: {Math.abs(totalReturnAmount)}</p>:<p className="bold text-danger">Remaining Due Amount: {Math.abs(totalReturnAmount)}</p>
                          }

                          {totalReturnAmount > 0 && <h6>Previous Due</h6>}
                          {totalReturnAmount > 0  && dueList &&
                            dueList?.map((item, index) => (
                              <div key={index} className="form-check">
                                <input
                                className="form-check-input"
                                  type="checkbox"
                                  id={`checkBox${index}`}
                                  checked={dueRowsInlineTable.some(obj => obj.so_no === item.so_no)?true:false}
                                  onChange={() => handleCheckboxChange(item.so_no, item)}
                                />
                                <label className="form-check-label">
                                  {item.so_no} - Due: {item.due} 
                                  {
                                    dueRowsInlineTable.some(obj => obj.so_no === item.so_no) && <input
                                    className="form-control admin-form-control"
                                      type="number"
                                      min="0"
                                      step="1"
                                      onChange={(e)=>handleChangeReturnAmountInput(item.so_no,'paid',e.target.value,item.due)}
                                      value={dueRowsInlineTable.find(obj => obj.so_no === item.so_no)?.paid??item.due}
                                      max={item.due}
                                    />
                                  }
                                  
                                </label>
                                
                              </div>
                              
                            ))}
                            </>
                        )
                      })()}





                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalesOrderReturn;
