import { createSlice } from '@reduxjs/toolkit'

export const SupplierSlice = createSlice({
    name: 'supplier',
    initialState: {
        supplierTypeList: [],
        supplierList: [],
        formValue: {
            "code": "",
            "supplier_type_id": "",
            "name": "",
            "opening_payable": "",
            "current_payable": "",
            "phone": "",
            "email": "",
            "address": "",
            "note": "",
            "category_wise_commision_but_calculate_squarfit": 0,
            "deduct_tax_on_total": 0
        }
    },
    reducers: {

        onChangeSupplierFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetSupplierFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setSupplierList: (state, action) => {
            state.supplierList = action.payload
        },
        setSupplierTypeList: (state, action) => {
            state.supplierTypeList = action.payload
        },
        onChangeToggleCheckboxInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = !state.formValue[`${action.payload.name}`];
        }

    },
})

export const { onChangeSupplierFormInput, resetSupplierFormValue, setSupplierList, setSupplierTypeList, onChangeToggleCheckboxInput } = SupplierSlice.actions

export default SupplierSlice.reducer