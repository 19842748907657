import React, {Fragment, useEffect, useRef, useState} from 'react';
import {AiOutlineCloudDownload, AiOutlineDelete} from "react-icons/ai";
import Breadcumb from '../Common/Breadcumb';
import { GetTransactionList,GetTransactionDateRangeFilterList } from '../../apiServices/TransactionApiService';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react'; 

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const TransactionList = () => {
    const [startDate,setStartDate]=useState("")
    const [endDate,setEndDate]=useState("")
    useEffect(()=>{
        (async () => {
            await GetTransactionList();
        })();
    },[])

    let dataList=useSelector((state)=>(state.transactionSlice.transactionList.data));
    const filterDateRange=async ()=>{
        await GetTransactionDateRangeFilterList({startDate,endDate});
    }

    const handleChangeStartDate=(value)=>{
        setStartDate(value)
    }

    const handleChangeEndDate=(value)=>{
        setEndDate(value)
    }
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Transaction",
        link:"/transaction/list",
        feature:"Transaction List"
    }
    const column = [
        {headerName:'Transaction No',field:"transaction_no",checkboxSelection:true,headerCheckboxSelection: true,tooltipField:"transaction_no"},
        {headerName:'Document No',field:"document_no",cellRenderer:params=>
        <>
            {
                params.value
            }
            
        </>},
        {headerName:'Document Type',field:"document_type",cellRenderer:params=>
        <>
            {
                params.value
            }
            
        </>},
        {headerName:'Date',field:"date",cellRenderer:params=>
        <>
            {
                params.value
            }
            
        </>},
        {headerName:'Amount',field:"amount",cellRenderer:params=>
        <>
            {
                params.value
            }
            
        </>},
        {headerName:'Method',field:"method",cellRenderer:params=>
        <>
            {
                params.value
            }
            
        </>},
    ]
    const defaultColDef={
        sortable:true,flex:1,resizable: true
    }
    const gridRef = useRef();
    const exportCSV=()=>{
        gridRef.current.api.exportDataAsCsv();
    }

    const onPageSize=(event)=>{
        gridRef.current.api.paginationSetPageSize(event.target.value)
       
    }
    const onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    }
    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-12">
                        <div className="card admin-card">
                            <div className="card-body">
                                <div className="">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="input-group mb-3">
                                                <input  type="text" className="form-control admin-form-control" placeholder="Page.." value="1"/>
                                                
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <select  className="form-control mx-2 form-select form-control-sm admin-form-control" onChange={onPageSize}>
                                                <option value="10">10 Per Page</option>
                                                <option value="20">20 Per Page</option>
                                                <option value="50">50 Per Page</option>
                                                <option value="100">100 Per Page</option>
                                                <option value="200">200 Per Page</option>
                                                <option value="500">500 Per Page</option>
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={()=>exportCSV()} className="btn btn-primary btn-sm mb-0 me-1 mt-1" type="button"><AiOutlineCloudDownload style={{fontSize:"1.1rem"}}></AiOutlineCloudDownload>Excel</button>
                                            
                                            
                                        </div>

                                        <div className="col-1">
                                        </div>

                                        
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <input onInput={onFilterTextBoxChanged}  type="text" className="form-control admin-form-control" placeholder="Search.." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                                <button className="btn  btn-success btn-sm mb-0" type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">

                                            <div className="input-group mb-3">
                                            <span className="mt-1 p-1">From Date:  </span> <input  type="date" className="form-control admin-form-control" placeholder="start date" value={startDate} onChange={(e)=>handleChangeStartDate(e.target.value)} />
                                                
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            
                                            <div className="input-group mb-3">
                                            <span className="mt-1 p-1">To Date:  </span> <input  type="date" className="form-control admin-form-control" placeholder="End Date" value={endDate} onChange={(e)=>handleChangeEndDate(e.target.value)}/>
                                                
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            
                                            <button onClick={()=>filterDateRange()} className="btn btn-info btn-sm mb-0 me-1 mt-1" type="button">Filter</button>
                                            
                                            
                                        </div>

                                        <div className="col-3">
                                        </div>

                                        
                                        
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="table-section ag-theme-alpine" >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={dataList}
                                                columnDefs={column} 
                                                defaultColDef={defaultColDef}
                                                animateRows={true}
                                                rowSelection='multiple'
                                                pagination={true}
                                                paginationPageSize={10}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TransactionList;