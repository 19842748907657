import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeDiscountFormInput, resetDiscountFormValue } from '../../../redux/slice/admin/DiscountSlice';
import { GetProductList } from '../../apiServices/ProductApiService';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { DiscountCreateApiService ,FillDiscountFormRequest} from '../../apiServices/DiscountApiService';
const animatedComponents = makeAnimated();

const DiscountCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.discountSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    let [target,setTarget]=useState('total');
    let [type,setType]=useState('allproduct');
    let [measurementtype,setMeasurementType]=useState('quantity');
    const [selectedProductOption, setSelectedProductOption] = useState(null);


    useEffect(()=>{
        (async () => {
            await GetProductList();
        })()
        store.dispatch(resetDiscountFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');
        
        if(id!==null){
            setRowID(id);
            (async () => {
               let getData = await FillDiscountFormRequest(id);
               store.dispatch(onChangeDiscountFormInput({ name: "title", value: getData.title }));
               store.dispatch(onChangeDiscountFormInput({ name: "start_date", value: getData.start_date }));
               store.dispatch(onChangeDiscountFormInput({ name: "end_date", value: getData.end_date }));
               store.dispatch(onChangeDiscountFormInput({ name: "quantity", value: getData.quantity }));
               store.dispatch(onChangeDiscountFormInput({ name: "value", value: getData.value }));
               store.dispatch(onChangeDiscountFormInput({ name: "measurement_value", value: getData.measurement_value }));
               store.dispatch(onChangeDiscountFormInput({ name: "type", value: getData.type }));
               store.dispatch(onChangeDiscountFormInput({ name: "amount_type", value: getData.amount_type }));
               store.dispatch(onChangeDiscountFormInput({ name: "target", value: getData.target }));
               store.dispatch(onChangeDiscountFormInput({ name: "measurement_type", value: getData.measurement_type }));
               store.dispatch(onChangeDiscountFormInput({ name: "measurement_value", value: getData.measurement_value }));
               
                if(getData.target==='signle'){
                    setTarget('signle')
                }else{
                    setTarget('total')
                }
                setType(getData.type)
                setMeasurementType(getData.measurement_type)
                const transformedProducts = getData.products.map(item => ({
                    value: item.id,
                    label: item.code
                }));
                console.log(transformedProducts)
                setSelectedProductOption(transformedProducts)

            })();
           
        }else{
            setRowID(0);
        }
        
    },[])

    let dataList=useSelector((state)=>(state.productSlice.productList.data));


    const handleChangeTarget=(e)=>{
        store.dispatch(onChangeDiscountFormInput({name:"target",value:e.target.value}))
        if(e.target.value==='signle'){
            setTarget('signle')

        }else{
            setTarget('total')
        }
    }
    const handleChangeType=(e)=>{
        store.dispatch(onChangeDiscountFormInput({name:"type",value:e.target.value}))
        if(e.target.value==='allproduct'){
            setType('allproduct')
        }else{
            setType('product')
        }
    }
    
    const saveChange =  async() => {
        const data ={
            title: formValue.title,
            start_date: formValue.start_date,
            end_date: formValue.end_date,
            quantity: formValue.quantity,
            value: formValue.value,
            type: formValue.type,
            amount_type: formValue.amount_type,
            target: formValue.target,
            measurement_type: formValue.measurement_type,
            measurement_value: formValue.measurement_value,
            product_ids:selectedProductOption,
        }
        if(isEmpty(data.title)){
            errorToast("Title Required !")
        }else if(isEmpty(data.start_date)){
            errorToast("Start Date Required !")
        }else if(isEmpty(data.end_date)){
            errorToast("End Date Required !")
        }else if(isEmpty(data.target)){
            errorToast("Target Required !")
        }else if(isEmpty(data.amount_type)){
            errorToast("Amount Type Required !")
        }else if(isEmpty(data.value)){
            errorToast("Amount Required !")
        }else{
            if(await DiscountCreateApiService(data,rowRd)){
                navigate("/discount/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Discount",
        link:"/discount/list",
        feature:rowRd !==0?"Update Discount":"Create Discount"
    }

    const productOptions = dataList?.map((item) => ({
        value: item.id,
        label: item.code,
      }));

    const handleProductChange =async (selectedOption) => {
        setSelectedProductOption(selectedOption);
    };
  


    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Title</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control `} placeholder="title" onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"title",value:e.target.value}))}} value={formValue.title} type="text" />
                                        
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Target</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{handleChangeTarget(e)}} className={`form-control admin-form-control`}>
                                                    <option value="">Choose One</option>

                                                    <option  selected={formValue.target==='total'} value="total">Total Order</option>
                                                    <option  selected={formValue.target==='signle'} value="signle">Product</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

   
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Start Date</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"start_date",value:e.target.value}))}} value={formValue.start_date} type="date"/>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                            <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field">End Date</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"end_date",value:e.target.value}))}} value={formValue.end_date} type="date"/>
                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Amount</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control `} placeholder="Amount" onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"value",value:e.target.value}))}} value={formValue.value} type="number" min={0} step={1}/>
                                        
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Amount Type</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({
                                                    name:'amount_type',
                                                    value:e.target.value
                                                }))}} className={`form-control admin-form-control`}>
                                                    <option value="">Choose One</option>

                                                    <option  selected={formValue.amount_type==='amount'} value="amount">Amount(TK)</option>
                                                    <option  selected={formValue.amount_type==='percentage'} value="percentage">Percentage(%)</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                {
                                    target ==='signle' && <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Measurement Type</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{
                                                    setMeasurementType(e.target.value)
                                                    store.dispatch(onChangeDiscountFormInput({
                                                        name:'measurement_type',
                                                        value:e.target.value
                                                    }))
                                                
                                            }} className={`form-control admin-form-control`}>
                                                    <option value="">Choose One</option>
                                                    
                                                    <option  selected={formValue.measurement_type==='quantity'} value="quantity">Quantity</option>
                                                    <option  selected={formValue.measurement_type==='squarefit'} value="squarefit">Total Squarefit</option>
                                                    <option  selected={formValue.measurement_type==='persquarefit'} value="persquarefit">Per Squarefit</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                    {
                                        measurementtype ==='quantity' && <div class="row form-row">
                                        <label for="name" className=" col-lg-3 col-form-label text-title-field required">Quantity</label>
                                        
                                        <div className="col-lg-9">
                                        <input className={`form-control admin-form-control `} placeholder="quantity" onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"quantity",value:e.target.value}))}} value={formValue.quantity} type="number" min={0} step={1}/>
                                    
                                        </div>
                                        
                                    </div>
                                    }
                                    {
                                        measurementtype ==='squarefit' && <div class="row form-row">
                                        <label for="name" className=" col-lg-3 col-form-label text-title-field required">Squarefit</label>
                                        
                                        <div className="col-lg-9">
                                        <input className={`form-control admin-form-control `} placeholder="Squarefit" onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"measurement_value",value:e.target.value}))}} value={formValue.measurement_value} type="number" min={0} step={1}/>
                                    
                                        </div>
                                        
                                    </div>
                                    }
                                    {
                                        measurementtype ==='persquarefit' && <div class="row form-row">
                                        <label for="name" className=" col-lg-3 col-form-label text-title-field required">Squarefit</label>
                                        
                                        <div className="col-lg-9">
                                        <input className={`form-control admin-form-control `} placeholder="Squarefit" onChange={(e)=>{store.dispatch(onChangeDiscountFormInput({name:"measurement_value",value:e.target.value}))}} value={formValue.measurement_value} type="number" min={0} step={1} readOnly/>
                                    
                                        </div>
                                        
                                    </div>
                                    }
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Type</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{handleChangeType(e)}} className={`form-control admin-form-control`}>
                                                    <option value="">Choose One</option>
                                                    
                                                    <option  selected={formValue.type==='allproduct'} value="allproduct">All Product</option>
                                                    <option  selected={formValue.type==='product'} value="product">Product</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {
                                        type==='product'  &&
                                        <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Product</label>
                                            <div class="col-lg-9">

                                            <Select
                                                onChange={handleProductChange}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                defaultValue={selectedProductOption}
                                                isMulti
                                                options={productOptions}
                                                isSearchable={true}
                                                placeholder="Choose One"
                                            />
                                               
                                            </div>
                                            
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default DiscountCreate;