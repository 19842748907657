import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    SupplierListURL,
    SupplierCreateURL,
    SupplierUpdateURL,
    SupplierShowURL,
    SupplierDeleteURL,
    SupplierTypeListRL
} from "../helper/Config";

import { onChangeSupplierFormInput, resetSupplierFormValue, setSupplierList, setSupplierTypeList } from '../../redux/slice/admin/SupplierSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function SupplierCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SupplierCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + SupplierUpdateURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetSupplierFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetSupplierTypeList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SupplierTypeListRL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSupplierTypeList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetSupplierList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SupplierListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSupplierList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function DeleteSupplier(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SupplierDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillSupplierFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SupplierShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeSupplierFormInput({ name: "code", value: formValue.code }));
            store.dispatch(onChangeSupplierFormInput({ name: "supplier_type_id", value: formValue.supplier_type_id }));
            store.dispatch(onChangeSupplierFormInput({ name: "name", value: formValue.name }));
            store.dispatch(onChangeSupplierFormInput({ name: "opening_payable", value: formValue.opening_payable }));
            store.dispatch(onChangeSupplierFormInput({ name: "current_payable", value: formValue.current_payable }));
            store.dispatch(onChangeSupplierFormInput({ name: "phone", value: formValue.phone }));
            store.dispatch(onChangeSupplierFormInput({ name: "email", value: formValue.email }));
            store.dispatch(onChangeSupplierFormInput({ name: "address", value: formValue.address }));
            store.dispatch(onChangeSupplierFormInput({ name: "note", value: formValue.note }));
            store.dispatch(onChangeSupplierFormInput({ name: "category_wise_commision_but_calculate_squarfit", value: formValue.category_wise_commision_but_calculate_squarfit }));
            store.dispatch(onChangeSupplierFormInput({ name: "deduct_tax_on_total", value: formValue.deduct_tax_on_total }));
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}