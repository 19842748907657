import { toast } from 'react-toastify';
let EmailRegx = /\S+@\S+\.\S+/;
let MobileRegx = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
class FormHelper {
    isEmpty(value) {
        return value.length === 0;
    }
    isMobile(value) {
        return MobileRegx.test(value);
    }
    isEmail(value) {
        return !EmailRegx.test(value);
    }
    isFitPasswordLength(value) {
        return value.length > 5 && value.length < 11 ? false : true
    }
    successToast(msg) {
        toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    errorToast(msg) {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
}
export const {
    isEmpty,
    isMobile,
    isEmail,
    isFitPasswordLength,
    successToast,
    errorToast,
    getBase64
} = new FormHelper();