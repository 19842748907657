import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    Customer,
    CustomerByCode
} from "../helper/Config";

import {
    setCustomerList,
    setCustomer
} from '../../redux/slice/admin/CustomerSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "application/json"
    }
}

export async function GetCustomerList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + Customer;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCustomerList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return []
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return []
    }
}


export async function GetCustomerByCode(code) {
    try {
        store.dispatch(show())
        let URL = BaseURL + CustomerByCode + "/" + code;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCustomer(result.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return []
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return []
    }
}