import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import PurchaseOrderRecieve from '../../components/Purchase/PurchaseOrderRecieve';

const PurchaseOrderRecievePage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <PurchaseOrderRecieve/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};

export default PurchaseOrderRecievePage;