import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeProductFormInput, resetProductFormValue,toggleManageOffer } from '../../../redux/slice/admin/ProductSlice';
import { ProductCreateApiService,FillProductFormRequest,GetProductGradeList } from '../../apiServices/ProductApiService';
import { GetCategoryList } from '../../apiServices/CategoryApiService';
import { GetSupplierList } from '../../apiServices/SupplierApiService';

const ProductCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.productSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    let [subCategories,setSubCategory]=useState([]);


    useEffect(()=>{
        const fetchData = async () => {
            await GetProductGradeList();
            await GetCategoryList();
            await GetSupplierList();
            
        };

        fetchData();
        store.dispatch(resetProductFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');
        
        if(id!==null){
            setRowID(id);
            (async () => {
                let vari = await FillProductFormRequest(id);
                changeCategoryDropdown(vari.category_id)
            })();
           
        }else{
            setRowID(0);
        }
        
    },[])

    let categoryList=useSelector((state)=>(state.categorySlice.categoryList.data));
    let productGradeList=useSelector((state)=>(state.productSlice.productGradetList.data));
    let supplierList=useSelector((state)=>(state.supplierSlice.supplierList.data));

    
    const saveChange =  async() => {
        const formData = new FormData();
        formData.append('image', formValue.image);
        formData.append('name', formValue.name);
        formData.append('category_id', formValue.category_id);
        formData.append('sub_category_id', formValue.sub_category_id);
        formData.append('product_grade_id', formValue.product_grade_id);
        formData.append('code', formValue.code);
        formData.append('supplier_id', formValue.supplier_id);
        formData.append('sqf_per_tiles', 0);
        formData.append('sqf_per_box', 0);
        formData.append('note', formValue.note);
        formData.append('description', formValue.description);
        formData.append('purchase_price', formValue.purchase_price);
        formData.append('sales_price', formValue.sales_price);
        formData.append('has_offer_price', formValue.has_offer_price);
        formData.append('offer_price', formValue.offer_price);
        if(isEmpty(formValue.category_id)){
            errorToast("Category required !")
        }
        else if(isEmpty(formValue.code)){
            errorToast("Code Required !")
        }else if(isEmpty(formValue.sub_category_id)){
            errorToast("SubCategory Required !")
        }else if(isEmpty(formValue.product_grade_id)){
            errorToast("Product Grade Required !")
        }else if(isEmpty(formValue.supplier_id)){
            errorToast("Supplier is Required !")
        }else {
            if(await ProductCreateApiService(formData,rowRd)){
                navigate("/product/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Product",
        link:"/supplier/list",
        feature:rowRd !==0?"Update Product":"Create Product"
    }
    const isNameValid = true;
    const isCodeValid = true;
    const isCategoryValid = true;
    const isSubCategoryValid = true;
    const isProductGradeValid = true;
    const isSupplierValid = true;
    
    const changeCategoryDropdown= (value)=>{
        setSubCategory([])
        store.dispatch(onChangeProductFormInput({name:"category_id",value:value}))
        let category_id_to_find = value; 
        console.log(categoryList)
        if (categoryList && Symbol.iterator in Object(categoryList)) {
            console.log(categoryList)
            for (const item of categoryList) {
            if (item.id === Number(category_id_to_find)) {
                setSubCategory(item.subcategory)
               
                break;
            }
        }
        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Code</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isCodeValid ? 'is-invalid' : '' }`} placeholder="code" onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"code",value:e.target.value}))}} value={formValue.code} type="text" />
                                        {!isCodeValid && (
                                            <div className="invalid-feedback">Code is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Name</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isNameValid ? 'is-invalid' : '' }`} placeholder="name" onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                        {!isNameValid && ( 
                                            <div className="invalid-feedback">Name is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Category</label>
                                            
                                            <div className="col-lg-9">
                                            <select onChange={(e)=>{changeCategoryDropdown(e.target.value)}} className={`form-control admin-form-control ${
                                            !isCategoryValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            categoryList?.map(item=>
                                                <option  key={item.id} selected={formValue.category_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                        {!isCategoryValid && (
                                            <div className="invalid-feedback">Category is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Sub Category</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"sub_category_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isSubCategoryValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            subCategories?.map(item=>
                                                <option  key={item.id} selected={formValue.sub_category_id===item.id} value={item.id}>{item.measurement.height} X {item.measurement.width} ({item.measurement.per_box_count} per/box)</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                        {!isSubCategoryValid && (
                                            <div className="invalid-feedback">Subcategory is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Supplier</label>
                                            
                                            <div className="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"supplier_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isSupplierValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            supplierList?.map(item=>
                                                <option  key={item.id} selected={formValue.supplier_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                        {!isSupplierValid && (
                                            <div className="invalid-feedback">Supplier is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Product Grade</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"product_grade_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isProductGradeValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            productGradeList?.map(item=>
                                                <option  key={item.id} selected={formValue.product_grade_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                        {!isProductGradeValid && (
                                            <div className="invalid-feedback">Product Grade is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Purchase Price</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"purchase_price",value:e.target.value}))}} value={formValue.purchase_price} type="number" step="1" min="0" />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                            <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field">Sales Price</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"sales_price",value:e.target.value}))}} value={formValue.sales_price} type="number" step="1" min="0" />
                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Has Offer Price</label>
                                            
                                            <div className="col-lg-9">
                                                <input class="form-check-input" type="checkbox" value="1" 
                                                checked={formValue.has_offer_price}
                                                onChange={(e)=>{store.dispatch(toggleManageOffer({name:"has_offer_price"}))}} />
                                    
                                            </div>
                                            
                                        </div>  
                                    </div>
                                    <div className="col-lg-6">
                                        <div class="row form-row">
                                                <label for="name" className=" col-lg-3 col-form-label text-title-field">Offer Price</label>
                                                
                                                <div className="col-lg-9">
                                                <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"offer_price",value:e.target.value}))}} value={formValue.offer_price} type="number" step="1" min="0" readOnly={!formValue.has_offer_price}/>
                                        
                                                </div>
                                                
                                            </div>
                                    </div>
                                    
           
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Note</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="note" onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"note",value:e.target.value}))}} value={formValue.note} type="text" />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                            <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field">Description</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="description" onChange={(e)=>{store.dispatch(onChangeProductFormInput({name:"description",value:e.target.value}))}} value={formValue.description} type="text" />
                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Image</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control required`} type="file" accept="image/*" onChange={(e)=>{
                                                if (e.target.files && e.target.files.length > 0) {
                                                    store.dispatch(onChangeProductFormInput({name:"image",value:e.target.files[0]}))
                                                }
                                                
                                                }
                                            } />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                           
                                </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default ProductCreate;