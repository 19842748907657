import React, { Fragment, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Breadcumb from "../Common/Breadcumb";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { GetCustomerByCode } from "../../apiServices/CustomerApiService";
import Card from "../Common/Card";
import { AgGridReact } from 'ag-grid-react'; 
import {AiOutlineCloudDownload, AiOutlineRedo,AiFillEye } from "react-icons/ai";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
const CustomerView = () => {
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    if (id !== null) {
      (async () => {
        await GetCustomerByCode(id);
      })();
    }
  }, []);

  let customerData = useSelector((state) => state.customerSlice.customer.data);

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Customer",
    link: "/customer/list",
    feature: "Customer",
  };
  const column = [
        {headerName:'SO No',field:"so_no",checkboxSelection:true,headerCheckboxSelection: true,tooltipField:"po_no"},
        {headerName:'Date',field:"date",tooltipField:"so_no"},

        {headerName:'Total',field:"total",tooltipField:"total"},
        {headerName:'Status',field:"status",cellRenderer:params=>
        <>
            {
                params.value==='pending' &&  <span className='badge bg-warning'>{params.value}</span> 
            }
            {
                params.value==='processing' &&  <span className='badge bg-info'>{params.value}</span> 
            }
            {
                params.value==='approved' &&  <span className='badge bg-success'>{params.value}</span> 
            }
            {
                params.value==='canceled' &&  <span className='badge bg-danger'>{params.value}</span> 
            }
            
            
        </>
        },
        {headerName:'Action',field:"so_no",cellRenderer:params=>
            <>
                <Link to={`/sales/order/${params.value}`}  className="btn text-info  p-2 mb-0 btn-sm"><AiFillEye size={15} /></Link>
                <Link to={`/sales/order/return/${params.value}/${params.data.customer_id}`}  className="btn text-danger  p-2 mb-0 btn-sm"><AiOutlineRedo size={15} /></Link>
                
            </>    
        },
    ]
  const defaultColDef={
        sortable:true,flex:1,resizable: true
    }
    const gridRef = useRef();
    const exportCSV=()=>{
        gridRef.current.api.exportDataAsCsv();
    }

    const onPageSize=(event)=>{
        gridRef.current.api.paginationSetPageSize(event.target.value)
       
    }
    const onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    }
  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2 ">
                <h5 class="mb-0 w-100 d-flex justify-content-between align-items-center">
                  <div>
                    {breadcumb.feature}{" "}
                    <span className="text-success border">
                      {id !== null ? id : ""}
                    </span>
                    <span className="text-success border"></span>
                  </div>
                </h5>
              </div>
              <div className="card-body">
                <div className=" mt-1">
                  <div class="row mt-2">
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Name:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.name}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Customer No:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.code}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Reference:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.reference}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 ">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Eamil:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-lg-6">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Address:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.address}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="row form-row">
                        <div className="col-lg-3">
                          <p>Phone:</p>
                        </div>

                        <div className="col-lg-9">
                          <p>{customerData && customerData.phone}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row mt-2">
                  
                    <div class="col-lg-3">
                      <Card value={customerData && customerData.order_count} title="Total Order" class="bg-primary"/>
                    </div>
                    <div class="col-lg-3">
                      <Card value={customerData && customerData.sum_of_total_order_amount} title="Total Purchase" class="bg-orange"/>
                    </div>
                    <div class="col-lg-3">
                      <Card value={customerData && customerData.sum_of_total_order_paid_amount} title="Total Paid" class="bg-success"/>
                    </div>
                    <div class="col-lg-3">
                      <Card value={customerData && customerData.total_due} title="Total Due" class="bg-danger"/>
                    </div>
                    
                  </div>
                  <div className="row mt-5">
                    <h6>Order List</h6>
                    <div className="col-12">
                        <div className="card admin-card">
                            <div className="card-body">
                                <div className="">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="input-group mb-3">
                                                <input  type="text" className="form-control admin-form-control" placeholder="Page.." value="1"/>
                                                
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <select  className="form-control mx-2 form-select form-control-sm admin-form-control" onChange={onPageSize}>
                                                <option value="10">10 Per Page</option>
                                                <option value="20">20 Per Page</option>
                                                <option value="50">50 Per Page</option>
                                                <option value="100">100 Per Page</option>
                                                <option value="200">200 Per Page</option>
                                                <option value="500">500 Per Page</option>
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={()=>exportCSV()} className="btn btn-primary btn-sm mb-0 me-1 mt-1" type="button"><AiOutlineCloudDownload style={{fontSize:"1.1rem"}}></AiOutlineCloudDownload>Excel</button>
                                            
                                            
                                        </div>

                                        <div className="col-1">
                                        </div>

                                        
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <input onInput={onFilterTextBoxChanged}  type="text" className="form-control admin-form-control" placeholder="Search.." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                                <button className="btn  btn-success btn-sm mb-0" type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="table-section ag-theme-alpine" >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={customerData && customerData.order ? customerData.order : []}
                                                columnDefs={column} 
                                                defaultColDef={defaultColDef}
                                                animateRows={true}
                                                rowSelection='multiple'
                                                pagination={true}
                                                paginationPageSize={10}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerView;
