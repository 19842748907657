import { createSlice } from '@reduxjs/toolkit'

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboardData: {},

    },
    reducers: {

        setDashboardData: (state, action) => {
            state.dashboardData = action.payload
        }

    },
})

export const { setDashboardData } = DashboardSlice.actions

export default DashboardSlice.reducer