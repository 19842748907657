import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    ProductListURL,
    ProductCreateURL,
    ProductUpdateURL,
    ProductShowURL,
    ProductDeleteURL,
    ProductGradeListURL,
    ProductListBySubCategory
} from "../helper/Config";

import { onChangeProductFormInput, resetProductFormValue, setProductList, setProductGradeList } from '../../redux/slice/admin/ProductSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data"
    }
}


export async function ProductCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + ProductUpdateURL + "/" + id + "?_method=PUT";
            method = "post";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetProductFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetProductGradeList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductGradeListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setProductGradeList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetProductList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setProductList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetProductListBySubCategory(subCategoryId) {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductListBySubCategory + "/" + subCategoryId;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            return result.data.data
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return []
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function DeleteProduct(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillProductFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + ProductShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeProductFormInput({ name: "name", value: formValue.name }));
            store.dispatch(onChangeProductFormInput({ name: "category_id", value: formValue.category_id }));
            store.dispatch(onChangeProductFormInput({ name: "sub_category_id", value: formValue.sub_category_id }));
            store.dispatch(onChangeProductFormInput({ name: "product_grade_id", value: formValue.product_grade_id }));
            store.dispatch(onChangeProductFormInput({ name: "code", value: formValue.code }));
            store.dispatch(onChangeProductFormInput({ name: "supplier_id", value: formValue.supplier_id }));
            store.dispatch(onChangeProductFormInput({ name: "sqf_per_tiles", value: 0 }));
            store.dispatch(onChangeProductFormInput({ name: "sqf_per_box", value: 0 }));
            store.dispatch(onChangeProductFormInput({ name: "note", value: formValue.note }));
            store.dispatch(onChangeProductFormInput({ name: "description", value: formValue.description }));
            store.dispatch(onChangeProductFormInput({ name: "purchase_price", value: formValue.purchase_price }));
            store.dispatch(onChangeProductFormInput({ name: "sales_price", value: formValue.sales_price }));
            store.dispatch(onChangeProductFormInput({ name: "has_offer_price", value: formValue.has_offer_price }));
            store.dispatch(onChangeProductFormInput({ name: "offer_price", value: formValue.offer_price }));
            return formValue;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}