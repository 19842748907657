import { createSlice } from '@reduxjs/toolkit'

export const EmployeeSalesTargetSlice = createSlice({
    name: 'employeeSalesTargetSlice',
    initialState: {
        salesTargetList: [],
        formValue: {
            'start_date': '',
            'end_date': '',
            'has_product': false,
            'target': '',
            'target_type': '',
            'note': '',
        }
    },

    reducers: {

        onChangeSalesTargetFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetSalesTargetFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setSalesTargetList: (state, action) => {
            state.salesTargetList = action.payload
        },
        onChangeDiscountFormInputCheckBox: (state, action) => {
            state.formValue.has_product = !state.formValue.has_product
        }
    },
})

export const { onChangeSalesTargetFormInput, resetSalesTargetFormValue, setSalesTargetList, onChangeDiscountFormInputCheckBox } = EmployeeSalesTargetSlice.actions

export default EmployeeSalesTargetSlice.reducer