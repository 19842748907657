import { createSlice } from '@reduxjs/toolkit'

export const PurchaseOrderSlice = createSlice({
    name: 'purchase',
    initialState: {
        purchaseList: [],
        purchasePendingList: [],
        purchaseOrderById: {},
        purchaseNoSeries: "",
        purchaseForm: {
            'po_no': '',
            'date': '',
            'recieved_by': ''
        },
        formValue: {
            'po_no': "",
            'date': "",
            'supplier_id': "",
            'purchased_by': "",
            'note': "",
            'total': "",
            'comission': "",
            'carrying_discount': "",
            'carrying_charge': "",
            'purchase_payable': "",
            'previous_payable': "",
            'total_payable': "",
            'products': [],
            'payments': []
        }
    },

    reducers: {
        onChangePurchaseReciveFormInput: (state, action) => {
            state.purchaseForm[`${action.payload.name}`] = action.payload.value;
        },
        onChangePurchaseFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetPurchaseFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => {
                if (state.formValue[i] === 'products' || state.formValue[i] === 'payments') {
                    return state.formValue[i] = []
                } else {
                    return state.formValue[i] = ""
                }

            });
        },
        setPurchaseList: (state, action) => {
            state.purchaseList = action.payload
        },
        setPurchasePendingList: (state, action) => {
            state.purchasePendingList = action.payload
        },
        setPurchaseOrderById: (state, action) => {
            state.purchaseOrderById = action.payload
        },
        setPurchaseNoSeries: (state, action) => {
            state.purchaseNoSeries = action.payload
        },
        resetPurchaseOrderById: (state, action) => {
            state.purchaseOrderById = {}
        },
        resetPurchaseForm: (state, action) => {
            Object.keys(state.purchaseForm).forEach((i) => {
                return state.purchaseForm[i] = ""
            });
        }

    },
})

export const { onChangePurchaseFormInput, resetPurchaseFormValue, setPurchaseList, setPurchaseNoSeries, setPurchaseOrderById, setPurchasePendingList, resetPurchaseOrderById, onChangePurchaseReciveFormInput, resetPurchaseForm } = PurchaseOrderSlice.actions

export default PurchaseOrderSlice.reducer