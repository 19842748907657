import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import TransactionList from '../../components/Transaction/TransactionList';
const TransactionListPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <TransactionList/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};
export default TransactionListPage;