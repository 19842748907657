import { createSlice } from '@reduxjs/toolkit'

export const TransactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        transactionList: [],
        creditList: [],
        creditCategory: [],
        formValue: {
            amount: "",
            discount: "",
            credit_category_id: "",
            credit_sub_category_id: "",
            document_no: "",
            method: "",
            bank_id: "",
            date: ""
        }
    },
    reducers: {
        onChangeTransactionFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetTransactionFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setTransactionList: (state, action) => {
            state.transactionList = action.payload
        },
        setCreditList: (state, action) => {
            state.creditList = action.payload
        },
        setCreditCategory: (state, action) => {
            state.creditCategory = action.payload
        }
    },
})

export const { setCreditList, setTransactionList, onChangeTransactionFormInput, resetTransactionFormValue, setCreditCategory } = TransactionSlice.actions

export default TransactionSlice.reducer