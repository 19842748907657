import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import {  onChangeMeasurementFormInput, resetMeasurementFormValue } from '../../../redux/slice/admin/MeasurementSlice';

import { MeasurementCreateApiService,FillMeasurementFormRequest } from '../../apiServices/MeasurementApiService';
const MeasurementCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.measurementSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        store.dispatch(resetMeasurementFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillMeasurementFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])



    const saveChange =  async() => {
        if(isEmpty(formValue.height)){
            errorToast("Height Required !")
        }else if(isEmpty(formValue.width)){
            errorToast("Width Required !")
        }else if(isEmpty(formValue.per_box_count)){
            errorToast("Per Box Count Required !")
        }else if(isEmpty(formValue.sft_sales)){
            errorToast("Sft Sales Required !")
        }else if(isEmpty(formValue.cm_to_inch_sales_height)){
            errorToast("Height (Inch) Required !")
        }else if(isEmpty(formValue.cm_to_inch_sales_width)){
            errorToast("Width (Inch) Required !")
        }else {
            if(await MeasurementCreateApiService(formValue,rowRd)){
                navigate("/measurement/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Product",
        link:"/measurement/list",
        feature:rowRd !==0?"Update Measurement":"Create Measurement"
    }

    const handleChangeHeight=(field,value)=>{
        store.dispatch(onChangeMeasurementFormInput({name:"height",value:parseInt(value,10)}))
        if(Number(formValue.width)>0){
            store.dispatch(onChangeMeasurementFormInput({name:"sft_purchase",value:Number(((Number(value)*Number(formValue.width)*Number(10.76))/Number(10000))).toFixed(4)}))
        }
        store.dispatch(onChangeMeasurementFormInput({name:"cm_to_inch_sales_height",value:parseInt(Math.ceil(Number(value)/Number(2.54)),10)}))
        calculateSftSale(formValue.cm_to_inch_sales_width,parseInt(Math.ceil(Number(value)/Number(2.54))))
    }
    const handleChangeWidth=(field,value)=>{
        store.dispatch(onChangeMeasurementFormInput({name:"width",value:parseInt(value,10)}))
        if(Number(formValue.height)>0){
            store.dispatch(onChangeMeasurementFormInput({name:"sft_purchase",value:Number((Number(value)*Number(formValue.height)*Number(10.76))/Number(10000)).toFixed(4)}))
        }
        store.dispatch(onChangeMeasurementFormInput({name:"cm_to_inch_sales_width",value:parseInt(Math.ceil(Number(value)/Number(2.54)),10)}))
        calculateSftSale(parseInt(Math.ceil(Number(value)/Number(2.54)),10),formValue.cm_to_inch_sales_height)
    }

    const calculateSftSale = (widthInch,heightInch)=>{
        console.log(heightInch,widthInch)
        let sftSales = Number(Number(heightInch)*Number(widthInch)/Number(144))
        console.log(sftSales)
        store.dispatch(onChangeMeasurementFormInput({name:"sft_sales",value:sftSales}))
    }
    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Height (cm)</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="Height" onChange={(e)=>{ handleChangeHeight('height',e.target.value)}} value={formValue.height} type="number" step="1"
                                  min="0"/>
                                            
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                        <label for="name" className=" col-lg-3 col-form-label text-title-field required">Height (Inch)</label>
                                        
                                        <div className="col-lg-9">
                                        <input className={`form-control admin-form-control`} placeholder="Height" onChange={(e)=>{  store.dispatch(onChangeMeasurementFormInput({name:"cm_to_inch_sales_height",value:parseInt(e.target.value,10)}))
                                        calculateSftSale(formValue.cm_to_inch_sales_width,e.target.value)}} value={formValue.cm_to_inch_sales_height} type="number" step="1"
                              min="0"/>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                                    
                                </div>
                                <div class="row mt-2">
                                <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Width(cm)</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="Width" onChange={(e)=>{handleChangeWidth('width',e.target.value)}} value={formValue.width} type="number" step="1"
                                  min="0"/>
                                        
                                            </div>
                                            
                                        </div>
                                    </div>
                                
                                <div class="col-lg-6">
                                    <div class="row form-row">
                                        <label for="email" className="col-lg-3 col-form-label text-title-field required">Width (Inch)</label>
                                        <div class="col-lg-9">
                                        <input className={`form-control admin-form-control`} placeholder="Width" onChange={(e)=>{  store.dispatch(onChangeMeasurementFormInput({name:"cm_to_inch_sales_width",value:parseInt(e.target.value,10)}))
                                        calculateSftSale(e.target.value,formValue.cm_to_inch_sales_height)}} value={formValue.cm_to_inch_sales_width} type="number" step="1"
                              min="0"/>
                                    
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Per Box Count</label>
                                            
                                            <div className="col-lg-9">
                                                <input className={`form-control admin-form-control `} placeholder="Per Box Count" onChange={(e)=>{store.dispatch(onChangeMeasurementFormInput({name:"per_box_count",value:parseInt(e.target.value,10)}))}} value={formValue.per_box_count} type="number" step="1"
                                    min="0" />
                                            <p>Per box: <span className='text-bold text-success'>{Number(Number(formValue.per_box_count)*Number(formValue.sft_purchase)).toFixed(4)}</span> sft</p>
                                            
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Sft Sales</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control `} placeholder="Sft Sales" onChange={(e)=>{store.dispatch(onChangeMeasurementFormInput({name:"sft_sales",value:e.target.value}))}} value={formValue.sft_sales} type="number" readOnly/>
                                            <p>Per box: <span className='text-bold text-success'>{Number(Number(formValue.per_box_count)*Number(formValue.sft_sales)).toFixed(4)}</span> sft</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Sft Purchase</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control `} placeholder="Sft Purchase" readOnly value={formValue.sft_purchase} type="number" />
                                            
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>

                               
                                
                               
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default MeasurementCreate;


