import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/style.css'
import './assets/css/admin/style.css'

import 'react-toastify/dist/ReactToastify.css';

import store from './redux/store/store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
     
  </React.StrictMode>
);

