import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    SalesTarget
} from "../helper/Config";

import { resetSalesTargetFormValue, setSalesTargetList } from '../../redux/slice/admin/EmployeeSalesTargetSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function SalesTargetCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesTarget
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + SalesTarget + "/" + id + "?_method=PUT";
            method = "post";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetSalesTargetFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}

export async function GetSalesTargetList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesTarget;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSalesTargetList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function DeleteSalesTarget(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesTarget + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillSalesTargetListFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesTarget + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            return formValue;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}