import React, { Fragment,Suspense,lazy } from 'react'
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
const Dashboard =lazy(() => import('../../components/Dashboard/Dashboard'));
export default function DashboardPage() {
  return (
    <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <Dashboard/>
                </Suspense>
            </MasterLayout>
        </Fragment>
  )
}
