import React from 'react'

export default function Card(props) {
  return (
    <div className={`card shadow-lg border rounded ${props.class}`} style={{minHeight:"160px"}}>
        <div className="card-body text-center text-white">
            <p className="fs-1">{props.title}</p>
            <span className="fs-3">
                {props.value}
            </span>
            <p>{props.children}</p>
        </div>
    </div>
  )
}
