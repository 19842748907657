import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeSalesTargetFormInput, resetSalesTargetFormValue,onChangeDiscountFormInputCheckBox } from '../../../redux/slice/admin/EmployeeSalesTargetSlice';
import { GetProductList } from '../../apiServices/ProductApiService';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SalesTargetCreateApiService ,FillSalesTargetListFormRequest} from '../../apiServices/SalesTargetApiService';
import { AiOutlineDelete} from "react-icons/ai";

const animatedComponents = makeAnimated();

const EmployeeSalesTargetCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.employeeSalesTargetSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    let [target,setTarget]=useState('yearly');
    let [targetType,setTargetType]=useState('quantity');
    const [selectedProductOption, setSelectedProductOption] = useState(null);
    let [levelRowsInlineTable, setLevelRowsInlineTable] = useState([]);


    useEffect(()=>{
        (async () => {
            await GetProductList();
        })()
        store.dispatch(resetSalesTargetFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');
        
        if(id!==null){
            
            setRowID(id);
            (async () => {
               let getData = await FillSalesTargetListFormRequest(id);
               store.dispatch(onChangeSalesTargetFormInput({name:"has_product",value:getData.has_product}))
               store.dispatch(onChangeSalesTargetFormInput({ name: "start_date", value: getData.start_date }));
               store.dispatch(onChangeSalesTargetFormInput({ name: "end_date", value: getData.end_date }));
               store.dispatch(onChangeSalesTargetFormInput({ name: "target", value: getData.target }));
               store.dispatch(onChangeSalesTargetFormInput({ name: "target_type", value: getData.target_type }));
               store.dispatch(onChangeSalesTargetFormInput({ name: "note", value: getData.note }));

                if(getData.target==='yearly'){
                    setTarget('yearly')
                }else if(getData.target==='monthly'){
                    setTarget('monthly')
                }else if(getData.target==='daily'){
                    setTarget('daily')
                }else if(getData.target==='range'){
                    setTarget('range')
                }
                if(getData.target_type==='quantity'){
                    setTargetType('quantity')
                }else if(getData.target_type==='amount'){
                    setTargetType('amount')
                }else if(getData.target_type==='squarefit'){
                    setTargetType('squarefit')
                }else if(getData.target_type==='profit'){
                    setTargetType('profit')
                }else if(getData.target_type==='customer'){
                    setTargetType('customer')
                }

                if(getData.has_product){
                    const transformedProducts = getData.products.map(item => ({
                        value: item.id,
                        label: item.code
                    }));
                    setSelectedProductOption(transformedProducts)
                }
                if(getData.levels){
                    setLevelRowsInlineTable([]);
                    getData.levels.forEach((item)=>{
                        setLevelRowsInlineTable((prevRows) => [
                            ...prevRows,
                            {
                              level: item.level,
                              measurement_value: item.measurement_value,
                              amount: item.amount,
                              amount_type: item.amount_type,
                            },
                          ]);
                    })
                    
                }

            })();
           
        }else{
            setRowID(0);
            store.dispatch(onChangeSalesTargetFormInput({name:"has_product",value:false}));
            store.dispatch(onChangeSalesTargetFormInput({name:"target",value:'yearly'}));
            store.dispatch(onChangeSalesTargetFormInput({name:"target_type",value:'quantity'}));
            setTarget('yearly');
            setTargetType('quantity');
        }
        
    },[])

    let dataList=useSelector((state)=>(state.productSlice.productList.data));


    const handleChangeTarget=(e)=>{
        store.dispatch(onChangeSalesTargetFormInput({name:"target",value:e.target.value}))
        if(e.target.value==='yearly'){
            setTarget('yearly')
        }else if(e.target.value==='monthly'){
            setTarget('monthly')
        }else if(e.target.value==='daily'){
            setTarget('daily')
        }else if(e.target.value==='range'){
            setTarget('range')
        }
    }

    const handleChangeTargetType=(e)=>{
        store.dispatch(onChangeSalesTargetFormInput({name:"target_type",value:e.target.value}))
        if(e.target.value==='quantity'){
            setTargetType('quantity')
        }else if(e.target.value==='amount'){
            setTargetType('amount')
        }else if(e.target.value==='squarefit'){
            setTargetType('squarefit')
        }else if(e.target.value==='profit'){
            setTargetType('profit')
        }else if(e.target.value==='customer'){
            setTargetType('customer')
        }
    }
    const saveChange =  async() => {
        let data ={
            start_date: formValue.start_date,
            end_date: formValue.end_date,
            has_product: formValue.has_product,
            target: formValue.target,
            target_type: formValue.target_type,
            note: formValue.note,
            product_ids: selectedProductOption,
            levels: levelRowsInlineTable,
        }

         if(isEmpty(data.target)){
            errorToast("Target Required !")
        }else if(isEmpty(data.target_type)){
            errorToast("Target Type Required !")
        }else{
            if(await SalesTargetCreateApiService(data,rowRd)){
                navigate("/employee/sales/target/list")
            }
            //console.log(data)
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Sales Target",
        link:"/employee/sales/target/list",
        feature:rowRd !==0?"Update Sales Target":"Create Sales Target"
    }


  
    const productOptions = dataList?.map((item) => ({
        value: item.id,
        label: item.code,
      }));

    const handleProductChange =async (selectedOption) => {
        setSelectedProductOption(selectedOption);
    };


    const handleDeleteRow = (index) => {

        let data = levelRowsInlineTable.filter((_, i) => i !== index)
        setLevelRowsInlineTable(data);

    };
    const addNewRow = () => {
        setLevelRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        level: `Level ${prevRows.length + 1}`,
        measurement_value: "",
        amount: "",
        amount_type: "percentage",
      },
    ]);
    
  };
  const handleChangeInput = (index, field, value) => {
    setLevelRowsInlineTable((prevRows) =>
      prevRows.map((row, i) =>
         i === index ? { ...row, [field]: value } : row
      )
    );
  };
    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                            <div class="row mt-2">
                                  
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Target Timeline</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{handleChangeTarget(e)}} className={`form-control admin-form-control`}>
                                                    <option  selected={formValue.target==='yearly'} value="yearly">Yearly Target</option>
                                                    <option  selected={formValue.target==='monthly'} value="monthly">Monthly Target</option>
                                                    <option  selected={formValue.target==='weekly'} value="weekly">Weekly Target</option>
                                                    <option  selected={formValue.target==='daily'} value="daily">Daily Target</option>
                                                    <option  selected={formValue.target==='range'} value="range">Custom Date Range Target</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Note</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeSalesTargetFormInput({name:"note",value:e.target.value}))}} value={formValue.note} type="text"/>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                    
                                <div class="row mt-2">
                                
                                    
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">TargetType</label>
                                            <div class="col-lg-9">
                                                <select onChange={(e)=>{handleChangeTargetType(e)}} className={`form-control admin-form-control`}>
                                                    <option  selected={formValue.target_type==='quantity'} value="quantity">Quantity</option>
                                                    <option  selected={formValue.target_type==='amount'} value="amount">Sales Amount</option>
                                                    <option  selected={formValue.target_type==='squarefit'} value="squarefit">Total Squarefit</option>
                                                    <option  selected={formValue.target_type==='squarefit'} value="customer">Total Customer</option>
                                                    <option  selected={formValue.target_type==='profit'} value="profit">Profit</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                    <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Start Date</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeSalesTargetFormInput({name:"start_date",value:e.target.value}))}} value={formValue.start_date} type="date" readOnly={formValue.target!=='range'}/>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

   
                                <div class="row mt-2">
                                    
                                    <div class="col-lg-6">
                                            <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field">End Date</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`}  onChange={(e)=>{store.dispatch(onChangeSalesTargetFormInput({name:"end_date",value:e.target.value}))}} value={formValue.end_date} type="date" readOnly={formValue.target!=='range'}/>
                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                
                                </div>

                               <div class="row mt-2">
                                   
                                   
                                    <div className="col-lg-6">
                                        <div className="row mt-2">
                                            <div className="col-lg-3"></div>
                                            <div className="form-group col-lg-9 mb-3">
                                                <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked"  checked={formValue.has_product} onChange={(e)=>{store.dispatch(onChangeDiscountFormInputCheckBox({name:"has_product"}))}}
                                                />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Product Specific Target ?
                                                </label>   
                                                
                                            </div>
                                    
                                    
                                        </div>
                                    </div>
                                    {
                                        formValue.has_product &&
                                        <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Product</label>
                                            <div class="col-lg-9">

                                            <Select
                                                onChange={handleProductChange}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                defaultValue={selectedProductOption}
                                                isMulti
                                                options={productOptions}
                                                isSearchable={true}
                                                placeholder="Choose One"
                                            />
                                               
                                            </div>
                                            
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="border mt-2">
                                    <div className="row ">
                                        <div className="col-12">
                                        <div className="table-responsive-2">
                                            <table class="table line-table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                <th class="col-md-3" style={{width:"15%"}}>Level</th>
                                                <th class="col-md-2">
                                                {
                                                    targetType ==='quantity' && 'Quantity'  
                                                }
                                                {
                                                    targetType ==='squarefit' && 'Squarefit'
                                                }
            
                                                {
                                                    targetType ==='amount' && 'Sales Amount'
                                                }
                                                {
                                                    targetType ==='profit' && 'Profit'
                                                }
                                                {
                                                    targetType ==='customer' && 'Total Customer'
                                                }
                                                </th>
                                                <th class="col-md-2">Amount</th>
                                                <th class="col-md-2">Amount Type</th>
                                                <th class="col-md-1">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    levelRowsInlineTable.map((row, index) => (
                                                        <tr key={index}>
                                                        
                                                    
                                                   

                                                    <td>
                                                        <input
                                                        className={`form-control admin-form-control`}
                                                        value={row.level}
                                                        type="text"
                                                        readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                        className={`form-control admin-form-control`}
                                                        value={row.measurement_value}
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        onChange={(e)=>handleChangeInput(index,"measurement_value",e.target.value)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                        className={`form-control admin-form-control`}
                                                        value={row.amount}
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        onChange={(e)=>handleChangeInput(index,"amount",e.target.value)}
                                                        />
                                                    </td>
                                                    
                                                    <td>
                                                        <select
                                                        className={`form-control admin-form-control`}
                                                        value={row.amount_type}
                                                        onChange={(e)=>handleChangeInput(index,"amount_type",e.target.value)}
                                                        >
                                                        <option  selected={row.amount_type==='percentage'} value="percentage">Percentage</option>
                                                        <option  selected={row.amount_type==='amount'} value="amount">Taka</option>
                                                        
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <AiOutlineDelete onClick={() => handleDeleteRow(index)} size={15}  className="action-icon"/>
                                                    </td>
                                                    </tr>
                                                    ))
                        
                                                }
                                                
                                                <tr>
                                                
                                                    <td colSpan={5} className="text-center" >
                                                        <button className="btn btn-sm btn-default" onClick={addNewRow}>Add New</button>
                                                    </td>
                                                
                                                </tr>
                                            
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                  
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default EmployeeSalesTargetCreate;