import React, { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {  isEmpty } from "../../helper/FormHelper";
import store from "../../../redux/store/store";
import {  Modal } from 'react-bootstrap';
import { AiFillPrinter } from "react-icons/ai";
import {GetSalesOrderById} from '../../apiServices/SalesOrderApiService'
import { PDFViewer } from '@react-pdf/renderer';
import CustomerInvoice from './CustomerInvoice';
const SalesOrderView = () => {

  const { id } = useParams();
  useEffect(() => {
    console.log(id)
    if (id !== null) {
      (async () => {
        await GetSalesOrderById(id)
      })();
    } 
  }, []);

  let soData=useSelector((state)=>(state.salesOrderSlice.salesOrderById));
  const saveChange = async () => {
    
    
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Sales Order",
    link: "/sales/order/list",
    feature: "Sales Order View",
  };
  
  const [show, setShow] = useState(false);
  

const handleClose = () => {
    setShow(false)
    
};
const handleShow =async () =>{
      setShow(true)
};
  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2 ">
                <h5 class="mb-0 w-100 d-flex justify-content-between align-items-center">
                  <div>
                  {breadcumb.feature}{" "}{" "}<span className="text-success border">{id !== null?id:""}</span>
                  <span className="text-success border"></span></div>
                  
                  <button  onClick={handleShow} className="btn btn-primary btn-sm mb-0 me-1 mt-1 float-right" type="button"><AiFillPrinter style={{fontSize:"1.1rem"}}/>Print Invoice</button>
                </h5>
                
              </div>
              <div className="card-body">
               
                <div className=" mt-1">
                <div class="row mt-2">
          <div class="col-lg-6 ">
            <div class="row form-row">
            <div className="col-lg-3">
            <p>
               Sales Date:
              </p>
            </div>
              

              <div className="col-lg-9">
                <p>{soData && soData.date}</p>
               
              </div>
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="row form-row">
            <div className="col-lg-3">
            <p>
               Customer:
              </p>
            </div>
              

              <div className="col-lg-9">
                <p>{soData && soData.customer?.name}</p>
               
              </div>
            </div>
          </div>
          
        </div>
          <div class="row">
              <div class="col-lg-6 ">
                <div class="row form-row">
                <div className="col-lg-3">
                <p>
                   Reference:
                  </p>
                </div>
                  

                  <div className="col-lg-9">
                    <p>{soData && soData.sales_reference?.name}</p>
                   
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
            <div class="row form-row">
              <div className="col-lg-3">
                    <p>
                  Status:
                </p>
              </div>
              

              <div className="col-lg-9">
                <p>{soData && soData.status}</p>
                
              </div>
            </div>
          </div>
            </div>

            <div class="row mt-2">
            
              <div class="col-lg-6">
                <div class="row form-row">
                  <div className="col-lg-3">
                        <p>
                      Note:
                    </p>
                  </div>
                  

                  <div className="col-lg-9">
                    <p>{soData && soData.note}</p>
                    
                  </div>
                </div>
              </div>
            </div>
            
                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                            <th class="col-md-4">Category</th>
                            <th class="col-md-4">SubCategory</th>
                            <th class="col-md-2">Product</th>
                            <th class="col-md-2">Box</th>
                            <th class="col-md-2">Quantity</th>
                            <th class="col-md-2">SquareFit</th>
                            <th class="col-md-2">Rate/Sft</th>
                            <th class="col-md-2">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            soData && soData.sales_order_items?.map((item)=>
                              <tr>

                                  <td>{item.category.name}</td>
                                  <td>{item.sub_category.measurement.height}X{item.sub_category.measurement.width}</td>
                                  <td>{item.product.name}</td>
                                  <td>{item.box}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.squarefit}</td>
                                  <td>{item.rate_per_squarefit}</td>
                                  <td>{item.total}</td>
                                  
                              
                              </tr>
    
                            )
                          }
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{soData && soData.sales_order_items && soData.sales_order_items.length > 0 && parseFloat(soData.sales_order_items.reduce((total, item) => Number(total) + Number(item.box), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{soData && soData.sales_order_items && soData.sales_order_items.length > 0 && parseFloat(soData.sales_order_items.reduce((total, item) => Number(total) + Number(item.quantity), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{soData && soData.sales_order_items && soData.sales_order_items.length > 0 && parseFloat(soData.sales_order_items.reduce((total, item) => Number(total) + Number(item.squarefit), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{soData && soData.sales_order_items && soData.sales_order_items.length > 0 && parseFloat(soData.sales_order_items.reduce((total, item) => Number(total) + Number(item.rate_per_squarefit), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{soData && soData.sales_order_items && soData.sales_order_items.length > 0 && parseFloat(soData.sales_order_items.reduce((total, item) => Number(total) + Number(item.total), 0)).toFixed(2)}</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
              <div class="col-lg-12  d-flex justify-content-end">
                <div class="row form-row">
                
                  

                  <div className="col-lg-12">
                  <p>Sub Total: {soData && soData.sub_total}</p>
                  <p>Discount: {soData && soData.discount}</p>
                  <p>Carrying Charge: {soData && soData.carrying_charge}</p>
                  <p>Labour Charge: {soData && soData.labour_charge}</p>
                  <p>VAT(%): {soData && soData.vat}</p>
                  <p>Total: {soData && soData.total}</p>

                  </div>
                </div>
              </div>
             
            </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4">Payment Method</th>
                              <th class="col-md-4">Bank</th>
                              <th class="col-md-2">Date</th>
                              <th class="col-md-2">Paid</th>
                              <th class="col-md-2">Due</th>
                              <th class="col-md-2">Note</th>
                            
                            </tr>
                          </thead>
                          <tbody>
                          {
                            soData && soData.payments?.map((item)=>
                              <tr>
                                  <td>{item.payment_method}</td>
                                  <td>{item.bank_name}</td>
                                  <td>{item.date}</td>
                                  <td>{item.paid}</td>
                                  <td></td>
                                  <td>{item.note}</td>
                                  
                              
                              </tr>
    
                            )
                          }
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4">Total</th>
                              <th class="col-md-2">{soData && soData.payments && soData.payments.length > 0 && parseFloat(soData.payments.reduce((total, item) => Number(total) + Number(item.paid), 0)).toFixed(2)}</th>
                              <th class="col-md-2">
                              {soData && soData.payments && soData.payments.length > 0
                                      ? parseFloat(Number(soData.total) - soData.payments.reduce((total, item) => Number(total) + Number(item.paid), 0)).toFixed(2)
                                      : soData && parseFloat(Number(soData.total)).toFixed(2)
                                        
                                    }
                                </th>
                              <th class="col-md-2"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Invoice <span className="text-success border">#{id !== null?id:""}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PDFViewer width="100%" height="100%">
          <CustomerInvoice  invoiceData={soData}/>
        </PDFViewer>
        </Modal.Body>
       
      </Modal>
      
    </Fragment>
  );
};

export default SalesOrderView;
