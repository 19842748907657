import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty,isEmail } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeUserCreateUpdateFormInput, resetUserCreateUpdateFormValue } from '../../../redux/slice/admin/UserManagementSlice';
import { GetUserRoleList } from '../../apiServices/UserRoleApiService';
import { UserCreateUpdateRequest,FillUserFormRequest } from '../../apiServices/UserManagementApiService';

const UserCreateUpdate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.userManagementSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetUserRoleList();
        })();
        store.dispatch(resetUserCreateUpdateFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillUserFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let userRoleList=useSelector((state)=>(state.userRoleSlice.userRole));
    // console.log(userRoleList)
    let isNameValid = true;
    let isEmailValid = true;
    let isStatusValid = true;
    let isPasswordValid=true;
    let isRoleValid = true;

    const saveChange =  async() => {
         isNameValid = !isEmpty(formValue.name);
         isEmailValid = !isEmail(formValue.email);
         isStatusValid = !isEmpty(formValue.status);
          isRoleValid = !isEmpty(formValue.role_id);

        if(isEmpty(formValue.name)){
            errorToast("Name Required !")
        }
        else if(isEmpty(formValue.email)){
            errorToast("Email Required !")
        }else if(isEmpty(formValue.role_id)){
            errorToast("Role Required !")
        }else if(isEmpty(formValue.password) && rowRd===0){
            errorToast("Password Required !")
        }else if(isEmpty(formValue.status)){
            errorToast("Status Required !")
        }
        else {

            if(await UserCreateUpdateRequest(formValue,rowRd)){
                navigate("/user/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"User Management",
        link:"/user/list",
        feature:rowRd !==0?"Update User":"Create User"
    }
    
    if(rowRd !==0){
         isPasswordValid = true;
    }else{
         isPasswordValid = true;
    }
    


    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Name</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isNameValid ? 'is-invalid' : '' }`} placeholder="Name" onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                    {!isNameValid && (
                                            <div className="invalid-feedback">Name is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Password</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isPasswordValid ? 'is-invalid' : '' }`} placeholder="Password" onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"password",value:e.target.value}))}} value={formValue.password} type="password" />
                                    {!isPasswordValid && ( 
                                            <div className="invalid-feedback">Password is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Email</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isEmailValid ? 'is-invalid' : '' }`} placeholder="Email" onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"email",value:e.target.value}))}} value={formValue.email} type="email" />
                                    {!isEmailValid && ( 
                                            <div className="invalid-feedback">Email is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Role</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"role_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isRoleValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            userRoleList?.map(item=>
                                                <option  key={item.id} selected={formValue.role_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isRoleValid && (
                                            <div className="invalid-feedback">Role is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                           <label for="name" className=" col-lg-3 col-form-label text-title-field required">Status</label>
                                            
                                            <div className="col-lg-9">
                                                <select onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"status",value:e.target.value}))}} className='form-control admin-form-control'  required>
                                                    <option  value="">Select One</option>
                                                    <option  selected={formValue.status === 'active'} value="active">Active</option>
                                                    <option selected={formValue.status === 'deactive'}  value="deactive">Deactive</option>
                                                </select>
                                                {!isStatusValid && (
                                                    <div className="invalid-feedback">Status is required.</div>
                                                )}
                                            </div>
                                            
                                        </div>
                                        
                                         
                                    </div>
                                    <div class="col-lg-6">
                                       
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default UserCreateUpdate;