import React from "react";
import { NavLink } from "react-router-dom";
export default function AddNewButton(props) {
  return (
    <NavLink
      
      className="btn btn-sm btn-success pointer mb-0 me-1 mt-1"
      to={props.url}
      end
    >
      <span>{props.title}</span>
    </NavLink>
  );
}
