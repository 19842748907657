import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    TransactionList,
    Transaction,
    CreditCategory,
    DocumentByCreditCategory,
    CreditTransaction,
    CreditTransactionList
} from "../helper/Config";

import { setCreditList, setTransactionList, resetTransactionFormValue, setCreditCategory } from '../../redux/slice/admin/TransactionSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}

export async function CashoutCreateApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + CreditTransaction
        let method = 'post';

        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetTransactionFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetTransactionList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + TransactionList;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setTransactionList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetCreditCategoryList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + CreditCategory;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCreditCategory(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetDocumentByCreditCategory(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + DocumentByCreditCategory + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            return result.data.data
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return []
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return []
    }
}
export async function GetTransactionDateRangeFilterList(params) {
    try {

        store.dispatch(show())
        let URL = BaseURL + TransactionList + "?start_date=" + params.startDate + "&end_date=" + params.endDate;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setTransactionList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetCreditList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + CreditTransactionList;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCreditList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetCreditDateRangeFilterList(params) {
    try {

        store.dispatch(show())
        let URL = BaseURL + CreditTransactionList + "?start_date=" + params.startDate + "&end_date=" + params.endDate;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCreditList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}