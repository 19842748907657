import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeWarehouseFormInput, resetWarehouseFormValue } from '../../../redux/slice/admin/WarehouseSlice';
import { WarehouseCreateApiService,FillWarehouseFormRequest,GetWarehouseTypeList } from '../../apiServices/WarehouseApiService';

const WarehouseCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.warehouseSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetWarehouseTypeList();
        })();
        store.dispatch(resetWarehouseFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillWarehouseFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let warehouseTypeList=useSelector((state)=>(state.warehouseSlice.warehouseTypeList.data));

    const saveChange =  async() => {
        if(isEmpty(formValue.code)){
            errorToast("Code Required !")
        }else if(isEmpty(formValue.name)){
            errorToast("Name Required !")
        }else if(isEmpty(formValue.google_map_link)){
            errorToast("Google Map Link Required !")
        }else if(isEmpty(formValue.warehouse_type_id)){
            errorToast("Warehouse Type Required !")
        }else if(isEmpty(formValue.status)){
            errorToast("Status Required !")
        }else if(isEmpty(formValue.address)){
            errorToast("Address Required !")
        }else {
            console.log(formValue)
            if(await WarehouseCreateApiService(formValue,rowRd)){
                navigate("/warehouse/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Account",
        link:"/warehouse/list",
        feature:rowRd !==0?"Update Warehouse":"Create Warehouse"
    }
    const isNameValid = true;
    const isCodeValid = true;
    const isGoogleMapValid = true;
    const isWarehouseTypeValid = true;
    const isAddressValid = true;
    const isStatusValid = true;

    
    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Code</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isCodeValid ? 'is-invalid' : '' }`} placeholder="code" onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"code",value:e.target.value}))}} value={formValue.code} type="text" />
                                        {!isCodeValid && (
                                            <div className="invalid-feedback">Code is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Name</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isNameValid ? 'is-invalid' : '' }`} placeholder="name" onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                        {!isNameValid && ( 
                                            <div className="invalid-feedback">Name is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Google Map Link</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isGoogleMapValid ? 'is-invalid' : '' }`} placeholder="google map link" onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"google_map_link",value:e.target.value}))}} value={formValue.google_map_link} type="text" />
                                            {!isGoogleMapValid && ( 
                                            <div className="invalid-feedback">Google map is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Warehouse Type</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"warehouse_type_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isWarehouseTypeValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            warehouseTypeList?.map(item=>
                                                <option  key={item.id} selected={formValue.warehouse_type_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isWarehouseTypeValid && (
                                            <div className="invalid-feedback">Warehouse Type is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                               
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                        <label for="name" className=" col-lg-3 col-form-label text-title-field required">Status</label>
                                            
                                        <div className="col-lg-9">
                                        <select onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"status",value:e.target.value}))}} className='form-control admin-form-control'  required>
                                        <option  value="">Select One</option>
                                        <option  selected={formValue.status === 'active'} value="active">Active</option>
                                        <option selected={formValue.status === 'deactive'}  value="deactive">Deactive</option>
                                        <option selected={formValue.status === 'removed'}  value="removed">Remove</option>
                                        
                                    </select>
                                    {!isStatusValid && (
                                        <div className="invalid-feedback">Status is required.</div>
                                      )}
                                        </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Address</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="address" onChange={(e)=>{store.dispatch(onChangeWarehouseFormInput({name:"address",value:e.target.value}))}} value={formValue.address} type="text" />
                                        {!isAddressValid && (
                                            <div className="invalid-feedback">Address is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default WarehouseCreate;