import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    BankCreateURL,
    BankUpdateURL,
    BankListURL,
    BankDeleteURL,
    BankShowURL,
    BankTypeListRL,
    CashBalanceURL
} from "../helper/Config";

import { onChangeBankFormInput, resetBankFormValue, setBankList, setBankAccountTypeList, setCashBalance } from '../../redux/slice/admin/BankSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function BankCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + BankCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + BankUpdateURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetBankFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetBankTypeList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + BankTypeListRL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setBankAccountTypeList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetBankList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + BankListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setBankList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function DeleteBank(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + BankDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillBankFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + BankShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeBankFormInput({ name: "bank_account_type_id", value: formValue.bank_account_type_id }));
            store.dispatch(onChangeBankFormInput({ name: "code", value: formValue.code }));
            store.dispatch(onChangeBankFormInput({ name: "name", value: formValue.name }));
            store.dispatch(onChangeBankFormInput({ name: "branch", value: formValue.branch }));
            store.dispatch(onChangeBankFormInput({ name: "account_name", value: formValue.account_name }));
            store.dispatch(onChangeBankFormInput({ name: "account_no", value: formValue.account_no }));
            store.dispatch(onChangeBankFormInput({ name: "opening_balance", value: formValue.opening_balance }));
            store.dispatch(onChangeBankFormInput({ name: "current_balance", value: formValue.current_balance }));
            store.dispatch(onChangeBankFormInput({ name: "note", value: formValue.note }));
            store.dispatch(onChangeBankFormInput({ name: "address", value: formValue.address }));
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetCashBalance() {
    try {
        store.dispatch(show())
        let URL = BaseURL + CashBalanceURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCashBalance(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}