import { createSlice } from '@reduxjs/toolkit'

export const ProductSlice = createSlice({
    name: 'product',
    initialState: {
        productList: [],
        productGradetList: [],
        formValue: {
            "name": "",
            "category_id": "",
            "sub_category_id": "",
            "product_grade_id": "",
            "code": "",
            "supplier_id": "",
            "image": null,
            "sqf_per_tiles": "",
            "sqf_per_box": "",
            "note": "",
            "description": "",
            "purchase_price": 0,
            "sales_price": 0,
            "has_offer_price": 0,
            "offer_price": 0,
        }
    },

    reducers: {

        onChangeProductFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetProductFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setProductList: (state, action) => {
            state.productList = action.payload
        },
        setProductGradeList: (state, action) => {
            state.productGradetList = action.payload
        },
        toggleManageOffer: (state, action) => {
            state.formValue[`${action.payload.name}`] = !state.formValue[`${action.payload.name}`]
        }

    },
})

export const { onChangeProductFormInput, resetProductFormValue, setProductList, setProductGradeList, toggleManageOffer } = ProductSlice.actions

export default ProductSlice.reducer