import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty,isEmail } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeUserCreateUpdateFormInput, resetUserCreateUpdateFormValue } from '../../../redux/slice/admin/UserManagementSlice';
import { UserAssignPackageRequest,FillUserFormRequest } from '../../apiServices/UserManagementApiService';
import {GetSalesTargetList } from '../../apiServices/SalesTargetApiService';
const EmployeeSalesTargetUserUpdate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.userManagementSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    let [level,setLevel]=useState([]);
    
    useEffect(()=>{
        (async () => {
            await GetSalesTargetList();
        })();
        store.dispatch(resetUserCreateUpdateFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillUserFormRequest(id);
                
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let salesTarget=useSelector((state)=>(state.employeeSalesTargetSlice.salesTargetList.data));

    let isEmailValid = true;
    let isRoleValid = true;

    const saveChange =  async() => {
         isEmailValid = !isEmail(formValue.email);

          isRoleValid = !isEmpty(formValue.package_id);


        if(isEmpty(formValue.email)){
            errorToast("Email Required !")
        }else if(isEmpty(formValue.package_id)){
            errorToast("Package Required !")
        }else if(isEmpty(formValue.package_level_starting_id)){
            errorToast("Package Level Required !")
        }
        else {

            if(await UserAssignPackageRequest(formValue,rowRd)){
                navigate("/employee/sales/target/user/list")
            }
        }
    }

    const changePackage=(e)=>{
        const selectedPackageId = e.target.value;
        store.dispatch(onChangeUserCreateUpdateFormInput({ name: "package_id", value: selectedPackageId }));
        // console.log(salesTarget)
        const selectedPackage = salesTarget.find(salesPackage => salesPackage.id === Number(selectedPackageId));
        console.log(selectedPackage)

        if (selectedPackage) {
            setLevel(selectedPackage.levels);
        } else {
            setLevel([]);
        }

    }
    const breadcumb={
        dashboard:"Dashboard",
        menu:"User Sales Target",
        link:"/employee/sales/target/user/list",
        feature:rowRd !==0?"Update User Package":"Create User"
    }

    


    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">


                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">User Email</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isEmailValid ? 'is-invalid' : '' }`} placeholder="Email" onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"email",value:e.target.value}))}} value={formValue.email} type="email" readOnly/>
                                    {!isEmailValid && ( 
                                            <div className="invalid-feedback">Email is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Package</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>changePackage(e)} className={`form-control admin-form-control ${
                                            !isRoleValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            salesTarget?.map(item=>
                                                <option  key={item.id} selected={formValue.package_id===item.id} value={item.id}>{item.target}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isRoleValid && (
                                            <div className="invalid-feedback">Package is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Select Starting Package Level</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeUserCreateUpdateFormInput({name:"package_level_starting_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isRoleValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            level?.map(i=>
                                                <option  key={i.id} selected={formValue.package_level_starting_id===i.id} value={i.id}>{i.level}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isRoleValid && (
                                            <div className="invalid-feedback">Package is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default EmployeeSalesTargetUserUpdate;