import { createSlice } from '@reduxjs/toolkit'

export const CategorySlice = createSlice({
    name: 'category',
    initialState: {
        measurementTypeList: [],
        categoryList: [],
        formValue: {
            "name": "",
            "measurement_type_id": "",
            "manage_subcategory": true
        }
    },
    reducers: {
        onChangeCategoryFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetCategoryFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setCategoryTypeList: (state, action) => {
            state.measurementTypeList = action.payload
        },
        toggleManageSubcategory: (state, action) => {
            state.formValue[`${action.payload.name}`] = !state.formValue[`${action.payload.name}`]
        }

    },
})

export const { onChangeCategoryFormInput, resetCategoryFormValue, setCategoryList, setCategoryTypeList, toggleManageSubcategory } = CategorySlice.actions

export default CategorySlice.reducer