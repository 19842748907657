import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    MeasurementListURL
} from "../helper/Config";

import { setMeasurementList, resetMeasurementFormValue, onChangeMeasurementFormInput } from '../../redux/slice/admin/MeasurementSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}

export async function MeasurementCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + MeasurementListURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + MeasurementListURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetMeasurementFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetMeasurementList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + MeasurementListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setMeasurementList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function DeleteMeasurement(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + MeasurementListURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillMeasurementFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + MeasurementListURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeMeasurementFormInput({
                name: "height",
                value: parseInt(formValue.height, 10)
            }));
            store.dispatch(onChangeMeasurementFormInput({
                name: "width",
                value: parseInt(formValue.width, 10)
            }));
            store.dispatch(onChangeMeasurementFormInput({
                name: "cm_to_inch_sales_height",
                value: parseInt(formValue.cm_to_inch_sales_height, 10)
            }));
            store.dispatch(onChangeMeasurementFormInput({
                name: "cm_to_inch_sales_width",
                value: parseInt(formValue.cm_to_inch_sales_width, 10)
            }));
            store.dispatch(onChangeMeasurementFormInput({ name: "per_box_count", value: formValue.per_box_count }));
            store.dispatch(onChangeMeasurementFormInput({ name: "sft_purchase", value: formValue.sft_purchase }));
            store.dispatch(onChangeMeasurementFormInput({ name: "sft_sales", value: formValue.sft_sales }));
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}