import React from "react";
import { NavLink } from 'react-router-dom';
export default function Breadcumb(props) {
  return (
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <NavLink to="/dashboard">{props.breadcumb.dashboard}</NavLink>
      </li>
      <li className="breadcrumb-item">
        <NavLink to={props.link}>
          {props.breadcumb.menu}
        </NavLink>
      </li>
      <li className="breadcrumb-item active">{props.breadcumb.feature}</li>
    </ol>
  );
}
