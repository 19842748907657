import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    SubCategoryListURL,
    SubCategoryCreateURL,
    SubCategoryUpdateURL,
    SubCategoryShowURL,
    SubCategoryDeleteURL,
} from "../helper/Config";

import { onChangeSubCategoryFormInput, resetSubCategoryFormValue, setSubCategoryList } from '../../redux/slice/admin/SubCategorySlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function SubCategoryCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SubCategoryCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + SubCategoryUpdateURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetSubCategoryFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetSubCategoryList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SubCategoryListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSubCategoryList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function DeleteSubCategory(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SubCategoryDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillSubCategoryFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SubCategoryShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeSubCategoryFormInput({ name: "category_id", value: formValue.category_id }));
            store.dispatch(onChangeSubCategoryFormInput({ name: "measurement_id", value: formValue.measurement_id }));
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}