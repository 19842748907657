import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeSubCategoryFormInput,resetSubCategoryFormValue } from '../../../redux/slice/admin/SubCategorySlice';
import { SubCategoryCreateApiService,FillSubCategoryFormRequest, } from '../../apiServices/SubCategoryApiService';

import { GetCategoryList } from '../../apiServices/CategoryApiService';
import {GetMeasurementList} from '../../apiServices/MeasurementApiService'

const SubCategoryCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.subCategorySlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetCategoryList();
            await GetMeasurementList();
        })();
        store.dispatch(resetSubCategoryFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillSubCategoryFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let categoryList=useSelector((state)=>(state.categorySlice.categoryList.data));
    let measurementList=useSelector((state)=>(state.measurementSlice.measurementList.data));

    const saveChange =  async() => {
        if(isEmpty(formValue.category_id)){
            errorToast("Category Required !")
        }else if(isEmpty(formValue.measurement_id)){
            errorToast("Measurement Required !")
        }else {
            console.log(formValue)
            if(await SubCategoryCreateApiService(formValue,rowRd)){
                navigate("/subcategory/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Product",
        link:"/subcategory/list",
        feature:rowRd !==0?"Update SubCategory":"Create SubCategory"
    }
    const isCategoryValid = true;
    const isMeasurementValid = true;


    
    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">    


                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        
                                            <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Category</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeSubCategoryFormInput({name:"category_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isCategoryValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            categoryList?.map(item=>
                                                <option  key={item.id} selected={formValue.category_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isCategoryValid && ( 
                                                <div className="invalid-feedback">Category is required.</div>
                                            )}
                                    
                                            </div>
                                                
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Size/Name</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeSubCategoryFormInput({name:"measurement_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isMeasurementValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            measurementList?.map(item=>
                                                <option  key={item.id} selected={formValue.measurement_id===item.id} value={item.id}>{item.height} X {item.width} ({item.per_box_count} per/box)</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                    {!isMeasurementValid && ( 
                                                <div className="invalid-feedback">Size is required.</div>
                                            )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                               
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default SubCategoryCreate;