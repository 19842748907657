import { createSlice } from '@reduxjs/toolkit'

export const MeasurementSlice = createSlice({
    name: 'measurement',
    initialState: {
        measurementList: [],
        formValue: {
            "height": "",
            "width": "",
            "supplier_id": "",
            "per_box_count": "",
            "cm_to_inch_sales_height": "",
            "cm_to_inch_sales_width": "",
            "sft_purchase": "",
            "sft_sales": ""
        }
    },
    reducers: {
        onChangeMeasurementFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetMeasurementFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setMeasurementList: (state, action) => {
            state.measurementList = action.payload
        }

    },
})

export const { onChangeMeasurementFormInput, resetMeasurementFormValue, setMeasurementList } = MeasurementSlice.actions

export default MeasurementSlice.reducer