import { createSlice } from '@reduxjs/toolkit'
export const CustomerSlice = createSlice({
    name: 'customer',
    initialState: {
        customerList: [],
        customer: {},
    },

    reducers: {

        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },
        setCustomer: (state, action) => {
            state.customer = action.payload
        }
    },
})

export const { setCustomerList, setCustomer } = CustomerSlice.actions

export default CustomerSlice.reducer