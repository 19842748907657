import React, {Fragment, useEffect} from 'react';
import Breadcumb from '../Common/Breadcumb';
import {GetDiscountProductList } from '../../apiServices/DiscountApiService';
import { useSelector } from 'react-redux';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const DiscountProductList = () => {

    useEffect(()=>{
        (async () => {
            await GetDiscountProductList();
        })();
    },[])

    let dataList=useSelector((state)=>(state.discountSlice.discountProductList));




    const breadcumb={
        dashboard:"Dashboard",
        menu:"Discount Product",
        link:"/discount/product/list",
        feature:"Discount Product List"
    }

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-12">
                        <div className="card admin-card">
                            <div className="card-body">
                                <h5 className='card-header'>Current Availble Discount</h5>
                                <div className="mt-2">
                                    <div className="row">
                                    
                                            <div className="col-12">
                                            <section id="labels" className='discount-section'>
                                                <div class="container">
                                                    <div class="row">
                                                    {
                                                        dataList && dataList?.map((item,index)=>(
                                                        <div class="col-sm-6 col-md-3">
                                                        <div class="dl">
                                                    
                                                            <div class="discount alizarin">{item.value} {item.amount_type==='amount'? '৳':"%"}
                                                                <div class="type">off</div>
                                                            </div>
                                                            <div class="descr">
                                                            <strong>Product Code List:</strong> <br></br>
                                                            {
                                                                item.products.length===0 && (<><strong>All Product</strong><br></br></>)
                                                            }
                                                            {
                                                                item.products.length>0 && (<>
                                                                    {
                                                                        item.products?.map((item,index)=>(
                                                                            <><strong>{index+1}. {item.code}-{item.name}</strong><br></br></>
                                                                        ))
                                                                    }
                                                                </>)
                                                            }
                                                            <br></br><strong>Duration:</strong> 
                                                            <p>{(new Date(item.start_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} To {(new Date(item.end_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                                                            </div>
                                                            <div class="ends">
                                                                <small>* Conditions and restrictions apply.</small>
                                                            </div>
                                                            <div class="coupon midnight-blue">
                                                                <a href="#code-1" class="open-code">
                                                                {
                                                                    item.measurement_type==='squarefit' &&"Buy "+item.measurement_value+" Sft"
                                                                }
                                                                {
                                                                    item.measurement_type==='quantity' &&"Buy "+item.quantity+" Box"
                                                                }
                                                                {
                                                                    item.measurement_type==='persquarefit' &&"Per Squarefit"
                                                                }
                                                                 
                                                                
                                                                </a>
                                                               
                                                            </div>
                                                        </div>
                                                        </div>
                                                       
                                                        
                                                        ))
                                                    }
                                                    </div>
                                                </div>
                                                </section>
                                            </div>

                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DiscountProductList;