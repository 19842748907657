import { createSlice } from '@reduxjs/toolkit'

export const SubCategorySlice = createSlice({
    name: 'subCategory',
    initialState: {
        subcategoryList: [],
        formValue: {
            "category_id": "",
            "measurement_id": ""
        }
    },
    reducers: {
        onChangeSubCategoryFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetSubCategoryFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setSubCategoryList: (state, action) => {
            state.subcategoryList = action.payload
        }
    },
})

export const { onChangeSubCategoryFormInput, resetSubCategoryFormValue, setSubCategoryList } = SubCategorySlice.actions

export default SubCategorySlice.reducer