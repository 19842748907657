import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import PurchaseOrderCreate from '../../components/Purchase/PurchaseOrderCreate';

const PurchaseOrderCreatePage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <PurchaseOrderCreate/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};

export default PurchaseOrderCreatePage;