import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import SalesOrderReturn from '../../components/Sales/SalesOrderReturn';

const SalesOrderReturnPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <SalesOrderReturn/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};

export default SalesOrderReturnPage;