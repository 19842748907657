import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import EmployeeSalesTargetList from '../../components/EmployeeSalesTarget/EmployeeSalesTargetList';
const EmployeeSalesTargetListPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <EmployeeSalesTargetList/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};
export default EmployeeSalesTargetListPage;