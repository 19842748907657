import React, {Fragment, useEffect, useState} from 'react';
import { AiFillSave } from 'react-icons/ai';
import Breadcumb from '../Common/Breadcumb';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { errorToast, isEmpty,isEmail } from '../../helper/FormHelper';
import store from '../../../redux/store/store';
import { onChangeSupplierFormInput, resetSupplierFormValue,onChangeToggleCheckboxInput } from '../../../redux/slice/admin/SupplierSlice';
import { SupplierCreateApiService,FillSupplierFormRequest,GetSupplierTypeList } from '../../apiServices/SupplierApiService';

const SupplierCreate = () => {
    let navigate=useNavigate();
    let formValue=useSelector((state)=>(state.supplierSlice.formValue));
    let [rowRd,setRowID]=useState(0);
    
    useEffect(()=>{
        (async () => {
            await GetSupplierTypeList();
        })();
        store.dispatch(resetSupplierFormValue())
        let params= new URLSearchParams(window.location.search);
        let id=params.get('id');

        if(id!==null){
            setRowID(id);
            (async () => {
                await FillSupplierFormRequest(id);
            })();
        }else{
            setRowID(0);
        }
        
    },[])

    let supplierTypeList=useSelector((state)=>(state.supplierSlice.supplierTypeList.data));
    // console.log(userRoleList)
    const saveChange =  async() => {
        if(isEmpty(formValue.supplier_type_id)){
            errorToast("Supplier type required !")
        }
        else if(isEmpty(formValue.code)){
            errorToast("Code Required !")
        }else if(isEmpty(formValue.name)){
            errorToast("Name Required !")
        }else if(isEmpty(formValue.phone)){
            errorToast("Phone Required !")
        }else if(isEmpty(formValue.email)){
            errorToast("Email Required !")
        }else if(isEmpty(formValue.address)){
            errorToast("Address Required !")
        }else {
            if(await SupplierCreateApiService(formValue,rowRd)){
                navigate("/supplier/list")
            }
        }
    }

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Account",
        link:"/supplier/list",
        feature:rowRd !==0?"Update Supplier":"Create Supplier"
    }
    const isNameValid = true;
    const isCodeValid = true;
    const isPhoneValid = true;
    const isEmailValid = true;
    const isSupplierTypedValid = true;
    const isAddressValid = true;


    

    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card admin-card">
                            <div class="card-header card-header-custom mb-2">
                                <h5 class="mb-0">{breadcumb.feature}</h5>
                            </div>
                            <div className="card-body">
                           
                                    
                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Code</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isCodeValid ? 'is-invalid' : '' }`} placeholder="code" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"code",value:e.target.value}))}} value={formValue.code} type="text" />
                                        {!isCodeValid && (
                                            <div className="invalid-feedback">Code is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Name</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isNameValid ? 'is-invalid' : '' }`} placeholder="name" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"name",value:e.target.value}))}} value={formValue.name} type="text" />
                                        {!isNameValid && ( 
                                            <div className="invalid-feedback">Name is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Phone</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isPhoneValid ? 'is-invalid' : '' }`} placeholder="phone" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"phone",value:e.target.value}))}} value={formValue.phone} type="text" />
                                    {!isPhoneValid && ( 
                                            <div className="invalid-feedback">Phone is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Supplier Type</label>
                                            <div class="col-lg-9">
                                            <select onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"supplier_type_id",value:e.target.value}))}} className={`form-control admin-form-control ${
                                            !isSupplierTypedValid ? 'is-invalid' : '' }`}>
                                        <option value="">Choose One</option>
                                        {
                                            supplierTypeList?.map(item=>
                                                <option  key={item.id} selected={formValue.supplier_type_id===item.id} value={item.id}>{item.name}</option>
                                            )
                                        }
                                        
                                       
                                    </select>
                                        {!isSupplierTypedValid && (
                                            <div className="invalid-feedback">Supplier Type is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Email</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control ${
                                            !isEmailValid ? 'is-invalid' : '' }`} placeholder="email" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"email",value:e.target.value}))}} value={formValue.email} type="email" />
                                    {!isEmailValid && ( 
                                            <div className="invalid-feedback">Email is required.</div>
                                          )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Address</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="address" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"address",value:e.target.value}))}} value={formValue.address} type="text" />
                                        {!isAddressValid && (
                                            <div className="invalid-feedback">Address is required.</div>
                                        )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Opening Payable</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="opening paybale" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"opening_payable",value:e.target.value}))}} value={formValue.opening_payable} type="number"
                                            step="0.01" />
                                    
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row form-row">
                                            <label for="email" className="col-lg-3 col-form-label text-title-field required">Current Payable</label>
                                            <div class="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="cuurent payable" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"current_payable",value:e.target.value}))}} value={formValue.current_payable} type="number"
                                            step="0.01" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6">
                                        <div className="form-group col-12">
                                            <input class="form-check-input" type="checkbox" value="1" 
                                            checked={formValue.category_wise_commision_but_calculate_squarfit}
                                            onChange={(e)=>{store.dispatch(onChangeToggleCheckboxInput({name:"category_wise_commision_but_calculate_squarfit"}))}} />
                                            <label class="form-check-label" for="flexCheckChecked">
                                            Category Wise Commision But Calculate Squarfit
                                            </label>   
                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                            <div class="row form-row">
                                            <label for="name" className=" col-lg-3 col-form-label text-title-field required">Note</label>
                                            
                                            <div className="col-lg-9">
                                            <input className={`form-control admin-form-control`} placeholder="note" onChange={(e)=>{store.dispatch(onChangeSupplierFormInput({name:"note",value:e.target.value}))}} value={formValue.note} type="text" />
                                    
                                            </div>
                                            
                                        </div>
                                </div>
                                </div>
                                <div class="row ">
                                <div class="col-lg-6">
                                    <div className="form-group col-12">
                                        <input class="form-check-input" type="checkbox" value="1" 
                                        checked={formValue.deduct_tax_on_total}
                                        onChange={(e)=>{store.dispatch(onChangeToggleCheckboxInput({name:"deduct_tax_on_total"}))}} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Deduct Tax On Total
                                        </label>   
                                    
                                    </div>
                                </div>  
                                
                            </div>
                                <div className="row mt-4">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button onClick={saveChange} className="btn  btn-success admin-btn ">
                                        <AiFillSave></AiFillSave> Save</button>
                                    </div>
                                </div>
                               
                                
                               
                               
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </Fragment>
    );
};

export default SupplierCreate;