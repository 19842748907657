import React, { Fragment, useEffect, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {  isEmpty } from "../../helper/FormHelper";
import store from "../../../redux/store/store";
import Select from 'react-select';
import {
  onChangePurchaseReciveFormInput
} from "../../../redux/slice/admin/PurchaseOrderSlice";
import {
  GetUserList
} from "../../apiServices/UserManagementApiService";
import {GetPendingPurchaseOrderList,GetPurchaseOrderById,PurchaseOrderRecieveApiService} from '../../apiServices/PurchaseOrderApiService'
import {GetWarehouseList} from '../../apiServices/WarehouseApiService'
const PurchaseOrderRecieve = () => {
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await GetPendingPurchaseOrderList();
      await GetWarehouseList();
      await GetUserList();
    })();
    
  }, []);

  let poList=useSelector((state)=>(state.purchaseOrderSlice.purchasePendingList));
  let warehouseList=useSelector((state)=>(state.warehouseSlice.warehouseList.data));
  let poData=useSelector((state)=>(state.purchaseOrderSlice.purchaseOrderById));
  let purchaseForm=useSelector((state)=>(state.purchaseOrderSlice.purchaseForm));
  let userList=useSelector((state)=>(state.userManagementSlice.userList.data));

  const purchaseItems = poData?.purchase_items?.map((item) => ({
    id: item.id,
    truck_no: "",
    warehouse_id: "",
  }));
  const changeWarehouseDropdown=(e,value)=>{
    const index = purchaseItems.findIndex((item) => item.id === value);
    if (index !== -1) {
      purchaseItems[index].warehouse_id = e.target.value
    }
  }
  const onChangeTruckInput=(e,value)=>{
    const index = purchaseItems.findIndex((item) => item.id === value);
    if (index !== -1) {
      purchaseItems[index].truck_no = e.target.value
    }
  }
  const poOptions = poList?.map((item) => ({
    value: item.id,
    label: item.po_no,
  }));
  const saveChange = async () => {
    let data ={
      'po_no':selectedPoOption.label,
      'received_at':purchaseForm.date,
      'received_by':purchaseForm.recieved_by,
      'purchase_items':purchaseItems
    }
    if (await PurchaseOrderRecieveApiService(data,)) {
      navigate("/purchase/order/list");
    }
    
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Purchase Order",
    link: "/purchase/order/list",
    feature: "Purchase Order View",
  };
  const [selectedPoOption, setSelectedPoOption] = useState(null);

  const handlePoChange =async (selectedOption) => {

    setSelectedPoOption(selectedOption);
    onChangePurchaseReciveFormInput({
      name:"po_no",
      value:selectedOption.label
    })
    await GetPurchaseOrderById(selectedOption.label);
  };

  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2">
                <h5 class="mb-0">
                  {breadcumb.feature}{" "}
                  <span className="text-success border"></span>
                </h5>
              </div>
              <div className="card-body">
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Date
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          onChange={(e) => {
                            store.dispatch(
                              onChangePurchaseReciveFormInput({
                                name: "date",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={purchaseForm.date}
                          type="date"
                        />
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        PO NO
                      </label>

                      <div className="col-lg-9">
                      <Select
                      value={selectedPoOption}
                      onChange={handlePoChange}
                      options={poOptions}
                      isSearchable={true}
                      placeholder="Choose One"
                    />
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                
                <div class="col-lg-6">
                  <div class="row form-row">
                    <label
                      for="name"
                      className=" col-lg-3 col-form-label text-title-field required"
                    >
                      Recieved By
                    </label>

                    <div className="col-lg-9">
                    <select
                                    onChange={(e) => {
                                      store.dispatch(
                                        onChangePurchaseReciveFormInput({
                                          name: "recieved_by",
                                          value: e.target.value,
                                        })
                                      );
                                    }}
                                    className={`form-control admin-form-control`}
                                    
                                  >
                                
                                    
                                    <option value="">Choose One</option>
                          {userList?.map((item) => (
                            <option
                              key={item.id}
                              selected={purchaseForm.recieved_by === item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
  ))}
                                  </select>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                  <div class="col-lg-6 ">
                    <div class="row form-row">
                    <div className="col-lg-3">
                    <p>
                       Purchase Date:
                      </p>
                    </div>
                      

                      <div className="col-lg-9">
                        <p>{poData && poData.date}</p>
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                    <div  className="col-lg-3">
                    <p>
                        Supplier:
                      </p>

                    </div>
                      
                      <div className="col-lg-9">
                      <p>{poData && poData.supplier?.name}</p>
                        
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                    <div className="col-lg-3">
                    <p>
                        Purchase By:
                      </p>
                    </div>
                      

                      <div className="col-lg-9">
                      <p>{poData && poData.purchased_by?.name}</p>
                       
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <div className="col-lg-3">
                            <p>
                          Note:
                        </p>
                      </div>
                      

                      <div className="col-lg-9">
                        <p>{poData && poData.note}</p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                 
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <div className="col-lg-3">
                            <p>
                          Status:
                        </p>
                      </div>
                      

                      <div className="col-lg-9">
                        <p>{poData && poData.status}</p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4">Truck</th>
                              <th class="col-md-4">Warehouse</th>
                              <th class="col-md-4">Category</th>
                              <th class="col-md-4">SubCategory</th>
                              <th class="col-md-2">Product</th>
                              <th class="col-md-2">Box</th>
                              <th class="col-md-2">Quantity</th>
                              <th class="col-md-2">Purchase Price</th>
                              <th class="col-md-2">Sales Price</th>
                              <th class="col-md-2">Sft(Purchase)</th>
                              <th class="col-md-2">Price(Purchase)</th>
                              <th class="col-md-2">Sft(Sale)</th>
                              <th class="col-md-2">Price(Sale)</th>

                            </tr>
                          </thead>
                          <tbody>
                          {
                            poData && poData.purchase_items?.map((item)=>
                              <tr>
                                  <td>
                                  <input
                                  className={`form-control admin-form-control`}
                                  onChange={(e) => {
                                    onChangeTruckInput(e,item.id)
                                  }}
                                  
                                  type="text"
                                />
                                  </td>
                                  <td>
                                  <select
                                    onChange={(e) => {
                                      changeWarehouseDropdown(e,item.id);
                                    }}
                                    className={`form-control admin-form-control`}
                                    
                                  >
                                    <option value="">Choose One</option>
                                    
                                    {warehouseList?.map((item) => (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                      
                                 
                                  </td>
                                  <td>{item.category.name}</td>
                                  <td>{item.sub_category.measurement.height}X{item.sub_category.measurement.width}</td>
                                  <td>{item.product.name}</td>
                                  <td>{item.box}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.purchase_price}</td>
                                  <td>{item.sales_price}</td>
                                  <td>{item.sft_purchase}</td>
                                  <td>{item.price_purchase}</td>
                                  <td>{item.sft_sales}</td>
                                  <td>{item.price_sales}</td>
                              
                              </tr>

                            )
                          }
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.box), 0)).toFixed(2)}</th>

                              <th class="col-md-2">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.quantity), 0)).toFixed(2)}</th>

                              <th class="col-md-2">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.purchase_price), 0)).toFixed(2)}</th>

                              <th class="col-md-2">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.sales_price), 0)).toFixed(2)}</th>

                              <th class="col-md-2">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.sft_purchase), 0)).toFixed(2)}</th>

                              <th class="col-md-4">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.price_purchase), 0)).toFixed(2)}</th>

                              <th class="col-md-4">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.sft_sales), 0)).toFixed(2)}</th>

                              <th class="col-md-4">{poData && poData.purchase_items && poData.purchase_items.length > 0 && parseFloat(poData.purchase_items.reduce((total, item) => Number(total) + Number(item.price_sales), 0)).toFixed(2)}</th>
                              <th class="col-md-4"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-lg-12  d-flex justify-content-end">
                    <div class="row form-row">
                    
                      

                      <div className="col-lg-12">
                      <p>Commission: {poData && poData.comission}</p>
                      <p>Carrying Discount: {poData && poData.carrying_discount}</p>
                      <p>Carrying Charge: {poData && poData.carrying_charge}</p>
                      <p>Purchase Payable: {poData && poData.purchase_payable}</p>
                      <p>Previous Payable: {poData && poData.previous_payable}</p>
                      <p>Total Payable: {poData && poData.total_payable}</p>

                      </div>
                    </div>
                  </div>
                 
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4">Payment Method</th>
                              <th class="col-md-4">Bank</th>
                              <th class="col-md-2">Date</th>
                              <th class="col-md-2">Paid</th>
                              <th class="col-md-2">Discount</th>
                              <th class="col-md-2">Due</th>
                              <th class="col-md-2">Note</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            poData && poData.payments?.map((item)=>
                              <tr>
                                  <td>{item.payment_method}</td>
                                  <td>{item.bank_name}</td>
                                  <td>{item.date}</td>
                                  <td>{item.paid}</td>
                                  <td>{item.discount}</td>
                                  <td>{item.due}</td>
                                  <td>{item.note}</td>
                                  
                              
                              </tr>

                            )
                          }
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{poData && poData.payments && poData.payments.length > 0 && parseFloat(poData.payments.reduce((total, item) => Number(total) + Number(item.paid), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{poData && poData.payments && poData.payments.length > 0 && parseFloat(poData.payments.reduce((total, item) => Number(total) + Number(item.discount), 0)).toFixed(2)}</th>
                              <th class="col-md-2">{poData && poData.payments && poData.payments.length > 0 && parseFloat(poData.payments.reduce((total, item) => Number(total) + Number(item.due), 0)).toFixed(2)}</th>
                              <th class="col-md-2"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PurchaseOrderRecieve;
