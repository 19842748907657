import { createSlice } from '@reduxjs/toolkit'

export const DiscountSlice = createSlice({
    name: 'product',
    initialState: {
        productList: [],
        discountList: [],
        discountProductList: [],
        formValue: {
            'title': '',
            'start_date': '',
            'end_date': '',
            'quantity': '',
            'value': '',
            'measurement_value': '',
            'type': '',
            'amount_type': '',
            'target': '',
            'measurement_type': ''
        }
    },

    reducers: {

        onChangeDiscountFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetDiscountFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setDiscountList: (state, action) => {
            state.discountList = action.payload
        },
        setProductList: (state, action) => {
            state.productList = action.payload
        },
        setDiscountProductList: (state, action) => {
            state.discountProductList = action.payload
        }

    },
})

export const { onChangeDiscountFormInput, resetDiscountFormValue, setDiscountList, setProductList, setDiscountProductList } = DiscountSlice.actions

export default DiscountSlice.reducer