import React, {Fragment, useEffect, useRef} from 'react';
import {AiOutlineCloudDownload} from "react-icons/ai";
import Breadcumb from '../Common/Breadcumb';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react'; 
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GetUserLoginHistoryList } from '../../apiServices/UserManagementApiService';

const UserLoginHistoryList = () => {
   
    useEffect(()=>{
        (async () => {
            await GetUserLoginHistoryList();
        })();
    },[])

    let dataList=useSelector((state)=>(state.userManagementSlice.userLoginHistoryList.data));

    
    const breadcumb={
        dashboard:"Dashboard",
        menu:"UserManagement",
        link:"/user/login/history/list",
        feature:"User Login History List"
    }
    const column = [
        {headerName:'Name',field:"user",cellRenderer:params=>
        <>
            {params.value.name}
        </>
        },
        {headerName:'Email',field:"user",cellRenderer:params=>
        <>
            <a href={`mailto:${params.value.email}`}>{params.value.email}</a>
        </>
        },
        {headerName:'IP Address',field:"ip_address",cellRenderer:params=>
        <>
            {params.value}
        </>
        },
        {headerName:'Login Time',field:"login_time",cellRenderer:params=>
            <>
            {new Date(params.value).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, 
            })}
            </>
        },
        {headerName:'User Agent',field:"user_agent",cellRenderer:params=>
        <>
            {params.value}
        </>
        }
        
    ]
    const defaultColDef={
        sortable:true,flex:1,resizable: true,minWidth : 100
    }
    const gridRef = useRef();
    const exportCSV=()=>{
        gridRef.current.api.exportDataAsCsv();
    }

    const onPageSize=(event)=>{
        gridRef.current.api.paginationSetPageSize(event.target.value)
       
    }
    const onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    }
    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-12">
                        <div className="card admin-card">
                            <div className="card-body">
                                <div className="">
                                <div className="row">
                                <div className="col-2">
                                    <div className="input-group mb-3">
                                        <input  type="text" className="form-control admin-form-control" placeholder="Page.." value="1"/>
                                        
                                    </div>
                                </div>
                                <div className="col-2">
                                    <select  className="form-control mx-2 form-select form-control-sm admin-form-control" onChange={onPageSize}>
                                        <option value="10">10 Per Page</option>
                                        <option value="20">20 Per Page</option>
                                        <option value="50">50 Per Page</option>
                                        <option value="100">100 Per Page</option>
                                        <option value="200">200 Per Page</option>
                                        <option value="500">500 Per Page</option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <button onClick={()=>exportCSV()} className="btn btn-primary btn-sm mb-0 me-1 mt-1" type="button"><AiOutlineCloudDownload style={{fontSize:"1.1rem"}}></AiOutlineCloudDownload>Excel</button>
                                    
                                    
                                </div>

                                <div className="col-1">
                                    
                                </div>

                                
                                <div className="col-4">
                                    <div className="input-group mb-3">
                                        <input onInput={onFilterTextBoxChanged}  type="text" className="form-control admin-form-control" placeholder="Search.." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                        <button className="btn  btn-success btn-sm mb-0" type="button">Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="table-section ag-theme-alpine" >
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={dataList}
                                        columnDefs={column} 
                                        defaultColDef={defaultColDef}
                                        animateRows={true}
                                        rowSelection='multiple'
                                        pagination={true}
                                        paginationPageSize={10}
                                        
                                    />
                                    </div>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserLoginHistoryList;