import { createSlice } from '@reduxjs/toolkit'

export const WarehouseSlice = createSlice({
    name: 'warehouse',
    initialState: {
        warehouseTypeList: [],
        warehouseList: [],
        formValue: {
            "code": "",
            "warehouse_type_id": "",
            "name": "",
            "google_map_link": "",
            "address": "",
            "status": ""
        }
    },
    reducers: {
        onChangeWarehouseFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },
        resetWarehouseFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setWarehouseList: (state, action) => {
            state.warehouseList = action.payload
        },
        setWarehouseTypeList: (state, action) => {
            state.warehouseTypeList = action.payload
        }

    },
})

export const { onChangeWarehouseFormInput, resetWarehouseFormValue, setWarehouseList, setWarehouseTypeList } = WarehouseSlice.actions

export default WarehouseSlice.reducer