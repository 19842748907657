import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import DiscountList from '../../components/Discount/DiscountList';
const DiscountListPage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <DiscountList/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};
export default DiscountListPage;