import React, {Fragment, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import {AiOutlineCloudDownload, AiOutlineEdit, AiOutlineDelete} from "react-icons/ai";
import Breadcumb from '../Common/Breadcumb';
import { GetProductList,DeleteProduct } from '../../apiServices/ProductApiService';
import { useSelector } from 'react-redux';
import { DeleteAlert } from '../../helper/DeleteAlert';
import { AgGridReact } from 'ag-grid-react'; 

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AddNewButton from '../Common/AddNewButton';

const ProductList = () => {

    useEffect(()=>{
        (async () => {
            await GetProductList();
        })();
    },[])

    let dataList=useSelector((state)=>(state.productSlice.productList.data));



    const DeleteItem = async (id) => {
        let Result = await DeleteAlert();
        if(Result.isConfirmed){
            let deleteResult= await DeleteProduct(id)
            if(deleteResult){
                await GetProductList();
            }
        }
    }
    const breadcumb={
        dashboard:"Dashboard",
        menu:"Product",
        link:"/product/list",
        feature:"Product List"
    }
    const column = [
        {headerName:'Name',field:"name",checkboxSelection:true,headerCheckboxSelection: true,tooltipField:"name"},
        {headerName:'Code',field:"code",tooltipField:"name"},
        {headerName:'Category',field:"category",tooltipField:"name",cellRenderer:params=>
            <>
                {params.value.name}
            </> 
        },
        {headerName:'Supplier',field:"supplier",tooltipField:"name",cellRenderer:params=>
            <>
                {params.value.name}
            </> 
        },
        {headerName:'Grade',field:"product_grade",tooltipField:"name",cellRenderer:params=>
            <>
                {params.value.name}
            </> 
        },
        {headerName:'Purchase Price',field:"purchase_price",tooltipField:"name"},
        {headerName:'Sales Price',field:"sales_price",tooltipField:"name"},
        {headerName:'Has Offer Price',field:"has_offer_price",tooltipField:"name",cellRenderer:params=>
            <>
                {params.value===1 && <span className="badge bg-success">yes</span>}
                {params.value===0 && <span className="badge bg-danger">no</span>}
            </> 
        },
        {headerName:'Offer Price',field:"offer_price",tooltipField:"name" },
        {headerName:'Image',field:"image",tooltipField:"name",cellRenderer:params=>
            <>
                <img src={params.value} alt="" srcset="" height="30" width="30"/>
            </> 
        },
        {headerName:'Action',field:"id",cellRenderer:params=>
            <>
                <Link to={`/product/create?id=${params.value}`}  className="btn text-info  p-2 mb-0 btn-sm"><AiOutlineEdit size={15} /></Link>
                <button className="btn  text-danger p-2 mb-0 btn-sm ms-2" onClick={DeleteItem.bind(this,params.value)}>
                    <AiOutlineDelete size={15} />
                </button>
            </>    
        },
    ]
    const defaultColDef={
        sortable:true,flex:1,resizable: true
    }
    const gridRef = useRef();
    const exportCSV=()=>{
        gridRef.current.api.exportDataAsCsv();
    }

    const onPageSize=(event)=>{
        gridRef.current.api.paginationSetPageSize(event.target.value)
       
    }
    const onFilterTextBoxChanged = (event) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    }
    return (
        <Fragment>
            <div className="container-fluid mt-5">
                <Breadcumb breadcumb={breadcumb}></Breadcumb>
                <div className="row">
                    <div className="col-12">
                        <div className="card admin-card">
                            <div className="card-body">
                                <div className="">
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="input-group mb-3">
                                                <input  type="text" className="form-control admin-form-control" placeholder="Page.." value="1"/>
                                                
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <select  className="form-control mx-2 form-select form-control-sm admin-form-control" onChange={onPageSize}>
                                                <option value="10">10 Per Page</option>
                                                <option value="20">20 Per Page</option>
                                                <option value="50">50 Per Page</option>
                                                <option value="100">100 Per Page</option>
                                                <option value="200">200 Per Page</option>
                                                <option value="500">500 Per Page</option>
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={()=>exportCSV()} className="btn btn-primary btn-sm mb-0 me-1 mt-1" type="button"><AiOutlineCloudDownload style={{fontSize:"1.1rem"}}></AiOutlineCloudDownload>Excel</button>
                                            
                                            
                                        </div>

                                        <div className="col-2">
                                            <AddNewButton url="/product/create" title="Add New"/>
                                        </div>

                                        
                                        <div className="col-4">
                                            <div className="input-group mb-3">
                                                <input onInput={onFilterTextBoxChanged}  type="text" className="form-control admin-form-control" placeholder="Search.." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                                <button className="btn  btn-success btn-sm mb-0" type="button">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <div className="table-section ag-theme-alpine" >
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={dataList}
                                                columnDefs={column} 
                                                defaultColDef={defaultColDef}
                                                animateRows={true}
                                                rowSelection='multiple'
                                                pagination={true}
                                                paginationPageSize={10}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductList;