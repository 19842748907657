import React, {Fragment, Suspense} from 'react';
import MasterLayout from "../../components/MasterLayout/MasterLayout";
import LazyLoader from "../../components/MasterLayout/LazyLoader";
import UserCreateUpdate from "../../components/UserManagement/UserCreateUpdate";

const UserCreateUpdatePage = () => {
    return (
        <Fragment>
            <MasterLayout>
                <Suspense fallback={<LazyLoader/>}>
                    <UserCreateUpdate/>
                </Suspense>
            </MasterLayout>
        </Fragment>
    );
};

export default UserCreateUpdatePage;