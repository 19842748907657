import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,

    SalesOrder,
    SalesNoSeries,
    CustomerNoSeries,
    PurchasePendingOrder,
    PurchaseRecieveOrder,
    CustomerTypeList,
    Customer,
    SalesOrderList,
    DueSalesOrderListByCustomerId,
    SalesOrderReturn

} from "../helper/Config";

import {
    resetPurchaseFormValue,
    setPurchasePendingList,
    resetPurchaseOrderById,
    resetPurchaseForm
} from '../../redux/slice/admin/PurchaseOrderSlice';
import { setSalesNoSeries, setCustomerNoSeries, setCustomerTypeList, setSalesList, setSalesOrderById, setDueSalesListByCustomer } from '../../redux/slice/admin/SalesOrderSlice'
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "application/json"
    }
}


export async function SalesOrderCreateApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesOrder
        let method = 'post';

        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetPurchaseFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        if (e.response.data.status === false) {
            if (e.response.data.error) {
                Object.keys(e.response.data.error).forEach((field) => {
                    e.response.data.error[field].forEach((errorMessage) => {
                        errorToast(`${errorMessage}`);
                    });
                });
            } else {
                errorToast("Something Went Wrong")
            }

        }
        console.log(e)
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function PurchaseOrderRecieveApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchaseRecieveOrder
        let method = 'post';
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetPurchaseOrderById())
            store.dispatch(resetPurchaseForm())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetSalesNoSeries() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesNoSeries;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSalesNoSeries(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetCustomerNoSeries() {
    try {
        store.dispatch(show())
        let URL = BaseURL + CustomerNoSeries
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {

            store.dispatch(setCustomerNoSeries(result.data.data))
            return result.data.data;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetSalesOrderList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesOrderList;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSalesList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetCustomerList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + Customer;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            return result.data.data.data
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return []
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return []
    }
}
export async function GetCustomerTypeList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + CustomerTypeList;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setCustomerTypeList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetPendingPurchaseOrderList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + PurchasePendingOrder;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setPurchasePendingList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function GetSalesOrderById(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesOrder + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setSalesOrderById(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetDueSalesOrderByCustomerId(salesId, customerId) {
    try {
        store.dispatch(show())
        let URL = BaseURL + DueSalesOrderListByCustomerId + "/" + customerId + "/" + salesId;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setDueSalesListByCustomer(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function CustomerOrderCreateApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + Customer
        let method = 'post';

        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            // store.dispatch(resetPurchaseFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}

export async function SalesOrderReturnApiService(postBody) {
    try {
        store.dispatch(show())
        let URL = BaseURL + SalesOrderReturn
        let method = 'post';

        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        if (e.response.data.status === false) {
            if (e.response.data.error) {
                Object.keys(e.response.data.error).forEach((field) => {
                    e.response.data.error[field].forEach((errorMessage) => {
                        errorToast(`${errorMessage}`);
                    });
                });
            } else {
                errorToast("Something Went Wrong")
            }

        }
        console.log(e)
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}