import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import {
    BaseURL,
    WarehouseListURL,
    WarehouseCreateURL,
    WarehouseUpdateURL,
    WarehouseShowURL,
    WarehouseDeleteURL,
    WarehouseTypeListRL
} from "../helper/Config";

import { onChangeWarehouseFormInput, resetWarehouseFormValue, setWarehouseList, setWarehouseTypeList } from '../../redux/slice/admin/WarehouseSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function WarehouseCreateApiService(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + WarehouseCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + WarehouseUpdateURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetWarehouseFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}
export async function GetWarehouseTypeList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + WarehouseTypeListRL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setWarehouseTypeList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function GetWarehouseList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + WarehouseListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setWarehouseList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}
export async function DeleteWarehouse(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + WarehouseDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillWarehouseFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + WarehouseShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeWarehouseFormInput({ name: "warehouse_type_id", value: formValue.warehouse_type_id }));
            store.dispatch(onChangeWarehouseFormInput({ name: "code", value: formValue.code }));
            store.dispatch(onChangeWarehouseFormInput({ name: "name", value: formValue.name }));
            store.dispatch(onChangeWarehouseFormInput({ name: "google_map_link", value: formValue.google_map_link }));
            store.dispatch(onChangeWarehouseFormInput({ name: "status", value: formValue.status }));
            store.dispatch(onChangeWarehouseFormInput({ name: "address", value: formValue.address }));
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}